import type { BlockProps } from '../../../types'
import Container from './Container'

const FormBlock = ({ block }: { block: BlockProps }) => {
  const { category, heading } = block?.data?.formData

  return (
    <Container tunes={block?.tunes} isInRow>
      <div className="border rounded shadow-sm p-3">
        <h3 className="text-center">{heading}</h3>
        <h5 className="text-center">Category - {category}</h5>
      </div>
    </Container>
  )
}

export default FormBlock
