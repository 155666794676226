import FullFormPreview from '../../../tools/FormBuilderTool/FormBuilderPreview'
import type { BlockProps, SelectOption } from '../../../types'
import Container from './Container'

const FormBuilderBlock = ({
  block,
  locationOptions,
}: {
  block: BlockProps
  locationOptions?: SelectOption[]
}) => {
  const { type, heading, description, fields, formSteps } = block?.data

  return (
    <Container tunes={block?.tunes} isInRow>
      <FullFormPreview
        type={type}
        heading={heading}
        description={description}
        fields={fields}
        steps={formSteps}
        locationOptions={locationOptions ?? []}
      />
    </Container>
  )
}

export default FormBuilderBlock
