import { generateRandomId } from '../../common/commonUtils'
import { getFormTemplate } from '../../tools/FormBuilderTool/FormBuilderTemplates'
import { type WebsiteDetailsProps } from '../../types'

/**
 * Handle what form to return based on whether the website has
 * autograb enabled or not.
 *
 * @param websiteDetails - An object containing the website details
 * @returns - A form or formBuilder block based on the website details
 */
export function handleForm(websiteDetails: WebsiteDetailsProps) {
  return websiteDetails.autograb
    ? {
        id: generateRandomId(10),
        type: 'form',
        data: {
          formData: {
            heading: 'Sell My Car Enquiry',
            category: 'Sell My Car Enquiry',
          },
        },
        tunes: {
          backgroundColor: {
            backgroundColor: 'light',
            customBackgroundColor: '#000000',
          },
          margin: {
            spacingTop: 0,
            spacingBottom: 0,
          },
          padding: {
            paddingTop: 5,
            paddingBottom: 5,
          },
          classname: {
            className: '',
          },
        },
      }
    : {
        id: generateRandomId(10),
        type: 'formBuilder',
        data: getFormTemplate('trade-in-default'),
        tunes: {
          backgroundColor: {
            backgroundColor: 'light',
            customBackgroundColor: '#000000',
          },
          margin: {
            spacingTop: 0,
            spacingBottom: 0,
          },
          padding: {
            paddingTop: 5,
            paddingBottom: 5,
          },
          classname: {
            className: '',
          },
        },
      }
}
