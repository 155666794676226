// EditorJS Components
import { ToolConstructable, ToolSettings } from '@editorjs/editorjs'
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import ImageTool from '@editorjs/image'
import List from '@editorjs/list'
import Paragraph from '@editorjs/paragraph'
import Table from '@editorjs/table'
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune'

// Custom Tools
import ImageBackgroundTool from './ImageBackgroundTool'
// Custom Inline Tools
import { CustomTools, Subscript, Superscript } from './inlineTools'
import {
  AlternatingColumnsTool,
  BrandDescriptionTool,
  BrandsTool,
  ButtonsTool,
  CardsTool,
  CarsTool,
  CloudinaryVideoTool,
  ColumnsTool,
  CustomComponentTool,
  FaqTool,
  FinanceCalculatorTool,
  FormBuilderTool,
  FormTool,
  HorizontalRuleTool,
  IconButtonsTool,
  ImageBannerTool,
  ImageSliderTool,
  LocationsTool,
  ModelColorsTool,
  ModelFeaturesTool,
  ModelVariantsTool,
  ModelsTool,
  ReviewsTool,
  SpecialOffersTool,
  TagsTool,
  TeamTool,
} from './tools'
// Custom Tunes
import {
  BackgroundColorTune,
  ClassnameTune,
  ContainerTune,
  MarginTune,
  PaddingTune,
  ScreensizeTune,
  TextColorTune,
} from './tunes'
import { BrandsDataProps, LocationProps, TemplateDataProps } from './types'

const getTunes = (saved: () => void) => {
  return {
    alignment: {
      class: AlignmentTuneTool,
      config: {
        default: 'left',
      },
    },
    classname: {
      class: ClassnameTune,
      config: {
        save: saved,
      },
    },
    container: {
      class: ContainerTune,
      config: {
        save: saved,
      },
    },
    backgroundColor: {
      class: BackgroundColorTune,
      config: {
        save: saved,
      },
    },
    textColor: {
      class: TextColorTune,
      config: {
        save: saved,
      },
    },
    margin: {
      class: MarginTune,
      config: {
        save: saved,
      },
    },
    padding: {
      class: PaddingTune,
      config: {
        save: saved,
      },
    },
    screensize: {
      class: ScreensizeTune,
      config: {
        save: saved,
      },
    },
  }
}

const getInlineTools = () => {
  return {
    superscript: {
      class: Superscript,
    },
    subscript: {
      class: Subscript,
    },
    customTools: {
      class: CustomTools,
    },
  }
}

const getHeaderConfig = (customTunes?: string[]) => {
  return {
    class: Header,
    inlineToolbar: true,
    tunes: customTunes ?? [
      'alignment',
      'container',
      'backgroundColor',
      'textColor',
      'margin',
      'padding',
      'classname',
    ],
  }
}

const getParagraphConfig = (customTunes?: string[]) => {
  return {
    class: Paragraph,
    inlineToolbar: true,
    tunes: customTunes ?? [
      'alignment',
      'container',
      'backgroundColor',
      'textColor',
      'margin',
      'padding',
      'classname',
    ],
    config: {
      preserveBlank: true,
    },
  }
}

const getImageConfig = (imageUrl: string) => {
  return {
    class: ImageTool,
    tunes: ['alignment', 'container', 'margin', 'screensize', 'classname'],
    config: {
      endpoints: {
        byFile: imageUrl,
      },
      captionPlaceholder: 'Image Alt (Recommended) OR Link URL (Optional)',
    },
  }
}

const getListConfig = (customTunes?: string[]): ToolConstructable | ToolSettings => {
  return {
    class: List,
    inlineToolbar: true,
    tunes: customTunes ?? [
      'alignment',
      'container',
      'backgroundColor',
      'textColor',
      'margin',
      'padding',
      'classname',
    ],
  }
}

const getTableConfig = () => {
  return {
    class: Table,
    inlineToolbar: true,
    tunes: ['container', 'margin', 'padding', 'classname'],
  }
}

const getHorizontalRuleConfig = (saved: () => void) => {
  return {
    class: HorizontalRuleTool as ToolConstructable,
    tunes: ['container', 'backgroundColor', 'margin', 'padding', 'classname'],
    config: {
      save: saved,
    },
  }
}

const getCarsConfig = (saved: () => void, customTunes?: string[]) => {
  return {
    class: CarsTool,
    config: {
      save: saved,
    },
    tunes: customTunes ?? ['backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getOffersConfig = (saved: () => void, customTunes?: string[]) => {
  return {
    class: SpecialOffersTool,
    config: {
      save: saved,
    },
    tunes: customTunes ?? ['backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getFormConfig = (saved: () => void, customTunes?: string[]) => {
  return {
    class: FormTool,
    config: {
      save: saved,
    },
    tunes: customTunes ?? ['backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getFormBuilderConfig = (
  locations: LocationProps[],
  imageUrl: string,
  customTunes?: string[]
) => {
  return {
    class: FormBuilderTool,
    config: {
      locations,
      imageUrl,
    },
    tunes: customTunes ?? ['backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getButtonsConfig = (saved: () => void, customTunes?: string[]) => {
  return {
    class: ButtonsTool,
    config: {
      save: saved,
    },
    inlineToolbar: true,
    tunes: customTunes ?? [
      'alignment',
      'container',
      'backgroundColor',
      'margin',
      'padding',
      'classname',
    ],
  }
}

const getTagConfig = (saved: () => void) => {
  return {
    class: TagsTool,
    config: {
      save: saved,
    },
    tunes: ['alignment', 'container', 'backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getCardsConfig = (imageUrl: string, saved: () => void) => {
  return {
    class: CardsTool,
    config: {
      imageUrl,
      save: saved,
    },
    tunes: ['alignment', 'container', 'backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getFaqConfig = (
  imageUrl: string,
  locations: LocationProps[],
  saved: () => void,
  isTopLevel: boolean
) => {
  const tools = {
    header: getHeaderConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    paragraph: getParagraphConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    image: getImageConfig(imageUrl),
    list: getListConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    buttonsTool: getButtonsConfig(saved, ['alignment', 'container', 'margin', 'classname']),
    table: getTableConfig(),
  }

  // Only allow these tools to be used at the top level (recursion issue)
  if (isTopLevel) {
    tools['columns'] = getColumnsConfig(imageUrl, locations, saved, false, [
      'container',
      'margin',
      'classname',
    ])
    tools['alternatingColumns'] = getAlternatingColumnsConfig(imageUrl, locations, saved, false)
  }

  return {
    class: FaqTool as ToolConstructable,
    config: {
      tools: {
        ...tools,
        ...getInlineTools(),
        ...getTunes(saved),
      },
      save: saved,
    },
    tunes: ['container', 'margin', 'classname'],
  }
}

const getLocationsConfig = (
  locations: LocationProps[],
  primaryLocationId: number,
  saved: () => void
) => {
  return {
    class: LocationsTool,
    config: {
      locations,
      primaryLocationId,
      save: saved,
    },
    tunes: ['margin', 'classname'],
  }
}

const getReviewsConfig = (locations: LocationProps[], saved: () => void) => {
  return {
    class: ReviewsTool as ToolConstructable,
    config: {
      locations,
      save: saved,
    },
    tunes: ['alignment', 'backgroundColor', 'textColor', 'margin', 'padding', 'classname'],
  }
}

const getTeamConfig = (saved: () => void) => {
  return {
    class: TeamTool,
    config: {
      save: saved,
    },
    tunes: ['container', 'margin', 'classname'],
  }
}

const getColumnsConfig = (
  imageUrl: string,
  locations: LocationProps[],
  saved: () => void,
  isTopLevel: boolean,
  customTunes?: string[],
  type?: string
) => {
  const tools = {
    header: getHeaderConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    paragraph: getParagraphConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    image: getImageConfig(imageUrl),
    list: getListConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    embed: Embed,
    buttonsTool: getButtonsConfig(saved, ['alignment', 'container', 'margin', 'classname']),
    table: getTableConfig(),
  }

  // Don't allow these on search template pages
  if (type !== 'search') {
    tools['cars'] = getCarsConfig(saved, ['container', 'margin', 'classname'])
    tools['form'] = getFormConfig(saved, ['container', 'margin', 'classname'])
    tools['formBuilder'] = getFormBuilderConfig(locations, imageUrl, ['margin', 'classname'])
  }

  // Only allow this tool to be used at the top level (recursion issue)
  if (isTopLevel) {
    tools['faq'] = getFaqConfig(imageUrl, locations, saved, false)
  }

  return {
    class: ColumnsTool as ToolConstructable,
    config: {
      locations,
      tools: {
        ...tools,
        ...getInlineTools(),
        ...getTunes(saved),
      },
      save: saved,
    },
    tunes: customTunes ?? ['container', 'backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getAlternatingColumnsConfig = (
  imageUrl: string,
  locations: LocationProps[],
  saved: () => void,
  isTopLevel: boolean
) => {
  const tools = {
    header: getHeaderConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    paragraph: getParagraphConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    image: getImageConfig(imageUrl),
    list: getListConfig(['alignment', 'container', 'textColor', 'margin', 'classname']),
    embed: Embed,
    buttonsTool: getButtonsConfig(saved, ['alignment', 'container', 'margin', 'classname']),
    table: getTableConfig(),
  }

  // Only allow this tool to be used at the top level (recursion issue)
  if (isTopLevel) {
    tools['faq'] = getFaqConfig(imageUrl, locations, saved, false)
  }

  return {
    class: AlternatingColumnsTool as ToolConstructable,
    config: {
      tools: {
        ...tools,
        ...getInlineTools(),
        ...getTunes(saved),
      },
      save: saved,
    },
    tunes: ['container', 'backgroundColor', 'textColor', 'margin', 'padding', 'classname'],
  }
}

const getImageBannerConfig = (
  brands: BrandsDataProps[],
  templateData: TemplateDataProps,
  imageUrl: string,
  saved: () => void
) => {
  return {
    class: ImageBannerTool,
    config: {
      save: saved,
      brands: brands ?? [],
      templateData,
      imageUrl,
      tools: {
        header: getHeaderConfig([
          'alignment',
          'container',
          'textColor',
          'margin',
          'padding',
          'classname',
        ]),
        paragraph: getParagraphConfig([
          'alignment',
          'container',
          'textColor',
          'margin',
          'padding',
          'classname',
        ]),
        buttonsTool: getButtonsConfig(saved, ['alignment', 'container', 'margin', 'classname']),
        ...getInlineTools(),
        ...getTunes(saved),
      },
    },
    tunes: ['margin', 'screensize', 'classname'],
  }
}

const getImageSliderConfig = (imageUrl: string, saved: () => void) => {
  return {
    class: ImageSliderTool as ToolConstructable,
    config: {
      imageUrl,
      save: saved,
    },
    tunes: ['container', 'margin', 'classname', 'backgroundColor'],
  }
}

const getWebsiteBrandsConfig = (brands: BrandsDataProps[], imageUrl: string, saved: () => void) => {
  return {
    class: BrandsTool as ToolConstructable,
    config: {
      brands: brands ?? [],
      imageUrl,
      save: saved,
    },
    tunes: ['alignment', 'container', 'backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getBrandModelsConfig = (
  brands: BrandsDataProps[],
  ownerManufacturerId: string,
  saved: () => void
) => {
  return {
    class: ModelsTool as ToolConstructable,
    config: {
      brands: brands?.filter((b) => b?.make) ?? [],
      ownerManufacturerId,
      save: saved,
    },
    tunes: ['alignment', 'container', 'backgroundColor', 'margin', 'padding', 'classname'],
  }
}

const getModelVariantsConfig = (
  brands: BrandsDataProps[],
  ownerManufacturerId: string,
  saved: () => void
) => {
  return {
    class: ModelVariantsTool as ToolConstructable,
    config: {
      brands: brands?.filter((b) => b?.make) ?? [],
      ownerManufacturerId,
      save: saved,
    },
    tunes: ['alignment', 'container', 'margin', 'classname'],
  }
}

const getModelColorsConfig = (
  brands: BrandsDataProps[],
  ownerManufacturerId: string,
  saved: () => void
) => {
  return {
    class: ModelColorsTool as ToolConstructable,
    config: {
      brands: brands?.filter((b) => b?.make) ?? [],
      ownerManufacturerId,
      save: saved,
    },
    tunes: ['alignment', 'container', 'margin', 'classname'],
  }
}

const getIconButtonsToolConfig = (saved: () => void) => {
  return {
    class: IconButtonsTool,
    config: {
      save: saved,
    },
    inlineToolbar: true,
    tunes: ['alignment', 'container', 'margin', 'classname'],
  }
}

const getBrandDescriptionConfig = (
  brands: BrandsDataProps[],
  imageUrl: string,
  saved: () => void
) => {
  return {
    class: BrandDescriptionTool,
    config: {
      brands: brands ?? [],
      imageUrl,
      save: saved,
    },
    tunes: ['alignment', 'container', 'margin', 'classname'],
  }
}

const getModelFeaturesConfig = (imageUrl: string[], saved: () => void) => {
  return {
    class: ModelFeaturesTool,
    config: {
      imageUrl,
      save: saved,
    },
    tunes: ['alignment', 'container', 'margin', 'classname'],
  }
}

const getFinanceCalculatorConfig = (saved: () => void) => {
  return {
    class: FinanceCalculatorTool,
    config: {
      save: saved,
    },
    tunes: ['container', 'backgroundColor', 'textColor', 'margin', 'padding', 'classname'],
  }
}

const getVideoConfig = (saved: () => void) => {
  return {
    class: CloudinaryVideoTool,
    config: {
      save: saved,
    },
    tunes: [
      'alignment',
      'container',
      'backgroundColor',
      'margin',
      'padding',
      'screensize',
      'classname',
    ],
  }
}

const getCustomComponentConfig = (
  templateData: TemplateDataProps,
  isAdmin: boolean,
  imageUrl: string,
  saved: () => void
) => {
  return {
    class: CustomComponentTool as ToolConstructable,
    config: {
      // Eventually want to add websiteId, dealershipSlug to this
      templateType: templateData.type,
      isAdmin,
      imageUrl,
      save: saved,
    },
    tunes: [
      'alignment',
      'container',
      'backgroundColor',
      'margin',
      'padding',
      'screensize',
      'classname',
    ],
  }
}

const getImageBackgroundConfig = (imageUrl: string, saved: () => void) => {
  return {
    class: ImageBackgroundTool as ToolConstructable,
    config: {
      save: saved,
      tools: {
        header: getHeaderConfig(['alignment', 'container', 'margin', 'classname']),
        paragraph: getParagraphConfig(['alignment', 'container', 'margin', 'classname']),
        image: getImageConfig(imageUrl),
        buttonsTool: getButtonsConfig(saved, ['alignment', 'container', 'margin', 'classname']),
        ...getInlineTools(),
        ...getTunes(saved),
      },
    },
  }
}

export {
  getAlternatingColumnsConfig,
  getBrandDescriptionConfig,
  getBrandModelsConfig,
  getButtonsConfig,
  getCardsConfig,
  getCarsConfig,
  getColumnsConfig,
  getCustomComponentConfig,
  getFaqConfig,
  getFinanceCalculatorConfig,
  getFormBuilderConfig,
  getFormConfig,
  getHeaderConfig,
  getHorizontalRuleConfig,
  getIconButtonsToolConfig,
  getImageBackgroundConfig,
  getImageBannerConfig,
  getImageConfig,
  getImageSliderConfig,
  getInlineTools,
  getListConfig,
  getLocationsConfig,
  getModelColorsConfig,
  getModelFeaturesConfig,
  getModelVariantsConfig,
  getOffersConfig,
  getParagraphConfig,
  getReviewsConfig,
  getTableConfig,
  getTagConfig,
  getTeamConfig,
  getTunes,
  getVideoConfig,
  getWebsiteBrandsConfig,
}
