import { useEffect } from 'react'

import EditorJS from '@editorjs/editorjs'

import { generateRandomId, initialiseStyling } from '../../../common/commonUtils'
import ImageBannerPreview from '../ImageBannerPreview'

// Content Active options
const contentActiveOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]

/**
 * The ContentStep component controls the text content of the Banner.
 * @param {*} stepIndex the index of this step
 * @param {*} isActive whether this step is active
 * @param {*} isModalOpen whether the modal is open
 * @param {*} activeIndexState an object tracking the previous and current active indexes
 * @param {*} state the current state of the ImageBanner instance
 * @param {*} config the config object for the ImageBanner instance
 * @param {*} syncedStateUpdate function to sync the state of the ImageBanner instance with the saved data
 * @param {*} onDataChange function to handle saving data changes
 * @param {*} getUpdatedData function to get the updated saved data
 * @returns The Content step with all required fields, embedded editor and previews
 */
const ContentStep = ({
  stepIndex,
  isActive,
  isModalOpen,
  activeIndexState,
  state,
  config,
  syncedStateUpdate,
  onDataChange,
  getUpdatedData,
}) => {
  // ! Embedded edtior components can't have any internal state management
  const editorJsID = generateRandomId(5)

  // Div that holds the embedded editor instance
  const ContentEditorHolder = () => (
    <div
      className="w-100 rounded border position-relative modal-embedded-editor-js-instance"
      id={editorJsID}
    >
      {state?.content?.active === 'true' && (
        <div
          className="position-absolute w-100 h-100"
          style={{
            backgroundColor: state?.overlay?.color,
            opacity: `calc(${state?.overlay?.opacity} / 100)`,
          }}
        ></div>
      )}
    </div>
  )

  const contentEditorjsInstance = new EditorJS({
    defaultBlock: 'paragraph',
    placeholder: 'Add your content...',
    holder: editorJsID,
    tools: config.tools,
    data: { blocks: state?.content?.blocks ?? [] },
    minHeight: 50,
    onReady: () => {
      if (state?.content?.blocks) {
        state?.content?.blocks.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
          // Initialise styling for blocks
          initialiseStyling(div, block.tunes)
        })
      }
    },
    onChange: async () => {
      // ! Custom tunes work correctly
      // ! in-built tunes (.e.g alignement) are not working
      const saveData = await contentEditorjsInstance.save()
      onDataChange({
        ...state,
        content: {
          ...state?.content,
          blocks: saveData?.blocks,
        },
      })

      // Add event listeners to the new blocks
      if (saveData?.blocks) {
        saveData?.blocks?.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
        })
      }
    },
  })

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      refreshContentState()
    }
  }, [isActive, isModalOpen])

  // Manual state refresh for the preview
  function refreshContentState() {
    const updatedData = getUpdatedData()
    syncedStateUpdate({ ...updatedData })
  }

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      <h6>Content</h6>
      <div className="row">
        {/* Content On/Off Step */}
        <div className="col-12 mb-3">
          <label htmlFor="bannerLayout-select">Do you want to add content to your banner?</label>
          <select
            className="form-control d-block"
            id="bannerLayout-select"
            defaultValue={state?.content?.active ?? undefined}
            onChange={(e) => {
              syncedStateUpdate({
                ...state,
                content: {
                  ...state?.content,
                  active: e.target?.value,
                },
              })
            }}
          >
            <option value={undefined}>Select an option</option>
            {contentActiveOptions?.map((type, index) => (
              <option key={`${type?.value}-${index}`} value={type?.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
        {state?.content?.active === 'true' && (
          <div className="col-12 z-2 mb-3" style={{ zIndex: 2 }}>
            <p className="mb-1">Edit your content below</p>
            <ContentEditorHolder />
            <button
              className="mt-2 btn btn-primary btn-sm"
              onClick={() => {
                refreshContentState()
              }}
            >
              Refresh Preview
            </button>
          </div>
        )}
        {/* Image Preview */}
        <div className="col-12 mt-2 mb-1" style={{ zIndex: 1 }}>
          <ImageBannerPreview state={state} />
        </div>
      </div>
    </div>
  )
}

export default ContentStep
