import { useCallback, useEffect, useState } from 'react'

import { BlockAPI } from '@editorjs/editorjs'
import { createRoot } from 'react-dom/client'

import { LabeledCheckbox, LabeledSelect } from '../common'
import { DefaultConfigProps, LocationProps, NodesProps, SelectOption, ToolboxProps } from '../types'

const RenderedReviewsComponent = ({
  data,
  onDataChange,
  locations,
}: {
  data: ReviewsToolData
  onDataChange: (data: ReviewsToolData) => void
  locations?: LocationProps[]
}) => {
  const [state, setState] = useState(data)
  const [profileOptions, setProfileOptions] = useState<SelectOption[]>([])

  const syncedStateUpdate = useCallback(
    (updatedData: ReviewsToolData) => {
      setState({ ...state, ...updatedData })
      onDataChange({ ...state, ...updatedData })
    },
    [state]
  )

  useEffect(() => {
    const locationGMBProfiles: SelectOption[] = [{ label: 'All Reviews', value: undefined }]
    if (!!locations && locations?.length > 0) {
      locations?.map((location) => {
        if (!!location?.gmb_profile_id) {
          locationGMBProfiles.push({ label: location?.name, value: location?.gmb_profile_id })
        }
      })
    }
    setProfileOptions(locationGMBProfiles)
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-column align-items-center bg-white text-dark border rounded shadow-sm pt-3">
            <h5 className="text-center">Reviews Section</h5>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="variant-select">Display Option</label>
                    <select
                      className="form-control"
                      id="variant-select"
                      defaultValue={data.variant}
                      onChange={(e) => syncedStateUpdate({ variant: e.target.value })}
                    >
                      <option value="simple">Simple</option>
                      <option value="highlighted">Highlighted</option>
                      <option value="full">Full</option>
                      <option value="mini">Mini</option>
                    </select>
                  </div>
                </div>
                {profileOptions?.length > 0 && (
                  <div className="col-12 col-md-6 col-lg-3">
                    <LabeledSelect
                      label="GMB Profile"
                      item={state}
                      itemName="gmbProfile"
                      updateItem={syncedStateUpdate}
                      options={profileOptions}
                    />
                  </div>
                )}
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-4 col-lg-2">
                  <div className="pl-4">
                    <LabeledCheckbox
                      label="Hide title"
                      item={state}
                      itemName="hideTitle"
                      updateItem={syncedStateUpdate}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2">
                  <div className="pl-4">
                    <LabeledCheckbox
                      label="Hide subtitle"
                      item={state}
                      itemName="hideSubtitle"
                      updateItem={syncedStateUpdate}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="pl-4">
                    <LabeledCheckbox
                      label="Automatically rotate carousel"
                      item={state}
                      itemName="automaticRotation"
                      updateItem={syncedStateUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type ReviewsToolData = {
  variant: string
  gmbProfile?: number
  hideTitle?: string
  hideSubtitle?: boolean
}

class ReviewsTool {
  private config: DefaultConfigProps
  private blockAPI: BlockAPI
  private data: ReviewsToolData
  private nodes: NodesProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Reviews',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-star">
          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
        </svg>`,
    }
  }

  constructor({ data, config, block }) {
    this.config = config
    this.blockAPI = block

    const defaultValue = {
      variant: 'simple',
      gmbProfile: undefined,
      hideTitle: undefined,
      hideSubtitle: undefined,
      automaticRotation: undefined,
    }

    this.data = Object.keys(data).length ? data : defaultValue

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData: ReviewsToolData) => {
      this.data = {
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedReviewsComponent
        onDataChange={onDataChange}
        data={this.data}
        locations={this.config.locations}
      />
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default ReviewsTool
