import { useEffect, useState } from 'react'

import { capitalise } from '../../../../entries/utils'
import { LabeledFileInput } from '../../../common'
import ImageBannerPreview from '../ImageBannerPreview'

// Image type options
const imageTypeOptions = [
  { value: 'default', label: 'Existing Image' },
  { value: 'custom', label: 'Custom Image' },
]

// Add category options if website is linked to any manufacturers (via dealership)
const getExistingCategoryOptions = (brands, templateData) => {
  const tempOptions = [{ value: 'default', label: 'Dealer Studio Images' }]

  // Check if the website is linked to any brands
  if (brands.filter((brand) => brand.manufacturer_id && brand.make).length > 0) {
    tempOptions.push({ value: 'manufacturer', label: 'Manufacturer Images' })
    tempOptions.push({ value: 'model', label: 'Model Images' })
  }

  if (brands.length === 0 && templateData?.manufacturer) {
    tempOptions.push({ value: 'manufacturer', label: 'Manufacturer Images' })
  }

  return tempOptions
}

// Default images used in our templates
const defaultImageOptions = [
  {
    value:
      'https://res.cloudinary.com/total-dealer/image/upload/v1644660263/td_next/finance-pre-approval_tcigpv',
    label: 'Finance Pre-Approval',
  },
  {
    value:
      'https://res.cloudinary.com/total-dealer/image/upload/v1644660263/td_next/service_ok7nd6',
    label: 'Service & Parts',
  },
  {
    value:
      'https://res.cloudinary.com/total-dealer/image/upload/v1645834405/td_next/test-drive_dbog8u',
    label: 'Test Drive',
  },
  {
    value:
      'https://res.cloudinary.com/total-dealer/image/upload/v1645834405/td_next/tradein_w4lnrv',
    label: 'Trade In',
  },
  {
    value: 'https://res.cloudinary.com/total-dealer/image/upload/v1646996581/td_next/contact',
    label: 'Contact',
  },
  {
    value: 'https://res.cloudinary.com/total-dealer/image/upload/v1648871838/td_next/careers',
    label: 'Careers',
  },
]

// Handles default state for manufacturer and model template pages
function handleDefaultState(
  templateData,
  tempManufacturers,
  state,
  syncedStateUpdate,
  setManufacturers,
  setSelectedManufacturer,
  setSelectedModel,
  setIsLoading,
  setImageState
) {
  // If the page is a manufacturer template page, set up component state to reflect that
  if (templateData?.manufacturer) {
    tempManufacturers = tempManufacturers.filter(
      (manufacturer) => `${manufacturer.value}` === templateData.manufacturer
    )

    fetch(Routes?.manufacturer_path(templateData.manufacturer) + '.json')
      .then((res) => res.json())
      .then((res) => {
        const banners = res.promotions.map((promotion) => ({
          value: promotion.image.url,
          label: promotion.name,
        }))
        // Default to manufacturer
        const updatedState = {
          ...state,
          image: {
            ...state?.image,
            type: 'default',
            existingCategory: 'manufacturer',
            existingFilters: {
              manufacturer: templateData?.manufacturer,
              model: undefined,
            },
            image: {
              url: 'default',
              previewUrl: banners[0]?.value,
            },
          },
        }
        syncedStateUpdate(updatedState)
        setImageState(updatedState)
        setManufacturers(tempManufacturers)
        setSelectedManufacturer(templateData?.manufacturer)
        setSelectedModel(undefined)
        setIsLoading(false)
      })
  }

  // If the page is a model template page, set up component state to reflect that
  if (templateData?.model) {
    fetch(`/models/${templateData?.model}.json`)
      .then((res) => res.json())
      .then((res) => {
        tempManufacturers = tempManufacturers.filter(
          (manufacturer) => manufacturer.value === res.manufacturer_id
        )
        // Default to model
        syncedStateUpdate({
          ...state,
          image: {
            ...state?.image,
            type: 'default',
            existingCategory: 'model',
            existingFilters: {
              manufacturer: res.manufacturer_id,
              model: templateData?.model,
            },
            image: {
              url: 'default',
              previewUrl: res.banner.url,
            },
          },
        })
        setManufacturers(tempManufacturers)
        setSelectedManufacturer(res.manufacturer_id)
        setSelectedModel(res.slug)
        setIsLoading(false)
      })
  }
}

/**
 * The ImageSelectStep component controls the selection of the image.
 * @param {*} stepIndex the index of this step
 * @param {*} isActive whether this step is active
 * @param {*} isModalOpen whether the modal is open
 * @param {*} activeIndexState an object tracking the previous and current active indexes
 * @param {*} state the current state of the ImageBanner instance
 * @param {*} syncedStateUpdate function to sync the state of the ImageBanner instance with the saved data
 * @param {*} getUpdatedData function to get the updated saved data
 * @param {*} imageEndpoint the endpoint to upload images
 * @param {*} brands an array of linked brands on the dealership
 * @param {*} templateData template data (if the page is a template)
 * @returns The Image Select step with all required fields and previews
 */
const ImageSelectStep = ({
  stepIndex,
  isActive,
  isModalOpen,
  activeIndexState,
  state,
  syncedStateUpdate,
  getUpdatedData,
  imageEndpoint,
  brands,
  templateData,
}) => {
  // State management
  const [isLoading, setIsLoading] = useState(
    (templateData?.manufacturer || templateData?.model) && state?.image?.image?.url === undefined
      ? true
      : false
  )
  const [manufacturers, setManufacturers] = useState([])
  const [selectedManufacturer, setSelectedManufacturer] = useState(
    state?.image?.existingFilters?.manufacturer ?? undefined
  )
  const [models, setModels] = useState([])
  const [selectedModel, setSelectedModel] = useState(
    state?.image?.existingFilters?.model ?? undefined
  )
  const [existingImages, setExistingImages] = useState([])
  const [imageState, setImageState] = useState(state?.image ?? undefined)

  // Set manufacturers
  useEffect(() => {
    let tempManufacturers = []
    brands?.map(
      (brand) =>
        brand.manufacturer_id &&
        brand.make &&
        tempManufacturers.push({ value: brand.manufacturer_id, label: brand.make })
    )

    if (brands.length === 0 && templateData?.manufacturer) {
      tempManufacturers.push({
        value: templateData.manufacturer,
        label: capitalise(templateData.manufacturer),
      })
    }

    // Only run if state data is empty
    if (state?.image?.image?.url === undefined) {
      // Set default state
      handleDefaultState(
        templateData,
        tempManufacturers,
        state,
        syncedStateUpdate,
        setManufacturers,
        setSelectedManufacturer,
        setSelectedModel,
        setIsLoading,
        setImageState
      )
    } else {
      // Handle manufacturer and model template pages (after default state is set)
      if (
        (templateData?.model || templateData?.manufacturer) &&
        state?.image?.existingFilters?.manufacturer
      ) {
        tempManufacturers = tempManufacturers.filter(
          (manufacturer) =>
            manufacturer.value === parseInt(state?.image?.existingFilters?.manufacturer)
        )
      }
    }

    setManufacturers(tempManufacturers)
  }, [])

  // Handle selected manufacturer change
  useEffect(() => {
    if (imageState?.existingCategory === 'default') setExistingImages(defaultImageOptions)

    // Fetch images for the selected manufacturer
    if (selectedManufacturer && imageState?.existingCategory === 'manufacturer') {
      fetch(Routes?.manufacturer_path(selectedManufacturer) + '.json')
        .then((res) => res.json())
        .then((res) => {
          const banners = res.promotions.map((promotion) => ({
            value: promotion.image.url,
            label: promotion.name,
            additionalDetails: promotion.image,
          }))
          setExistingImages(banners)
        })
    }

    // Fetch models for the selected manufacturer if the category is model
    if (selectedManufacturer && imageState?.existingCategory === 'model') {
      fetch(Routes?.manufacturer_models_path(selectedManufacturer) + '.json')
        .then((res) => res.json())
        .then((res) => {
          let tempModels = res.map((model) => ({ value: model.slug, label: model.name }))

          // If the page is a model template page, only allow the model to be selected
          if (templateData?.model) {
            tempModels = tempModels.filter((model) => model.value === templateData.model)
          }

          setModels(tempModels)
        })
    }
  }, [selectedManufacturer, imageState?.existingCategory])

  // Handle selected model change
  useEffect(() => {
    if (selectedModel) {
      fetch(`/models/${selectedModel}.json`)
        .then((res) => res.json())
        .then((res) => {
          setExistingImages(
            res.banner.url
              ? [
                  {
                    value: res.banner.url,
                    label: `${res.name} Main Banner`,
                    additionalDetails: res.banner,
                  },
                ]
              : []
          )
        })
    }
  }, [selectedModel])

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()
      syncedStateUpdate({ ...updatedData, image: { ...updatedData?.image, ...imageState } })
    }
  }, [isActive, isModalOpen])

  // Save state updates when image state is updated
  useEffect(() => {
    const updatedData = getUpdatedData()
    syncedStateUpdate({ ...updatedData, image: { ...updatedData?.image, ...imageState } })
  }, [imageState])

  const handleImageTypeChange = (e) => {
    setImageState({
      ...imageState,
      type: e.target.value,
    })
  }

  const handleImageExistingCategoryChange = (e) => {
    setImageState({
      ...imageState,
      existingCategory: e.target?.value,
      existingFilters: {
        ...state?.image?.existingFilters,
        model: undefined,
      },
      image: undefined,
    })
    if (state?.image?.existingFilters?.manufacturer) {
      setSelectedManufacturer(state?.image?.existingFilters?.manufacturer)
    }
  }

  const handleImageExistingFiltersManufacturerChange = (e) => {
    setSelectedManufacturer(e.target?.value)
    setImageState({
      ...imageState,
      existingFilters: {
        manufacturer: e.target?.value,
        model: undefined,
      },
      image: undefined,
    })
  }

  const handleImageExistingFiltersModelChange = (e) => {
    setSelectedModel(e.target?.value)
    setImageState({
      ...imageState,
      existingFilters: {
        ...state?.image?.existingFilters,
        model: e.target?.value,
      },
      image: undefined,
    })
  }

  const handleExistingImageChange = (e) => {
    const selectedImage = existingImages.find((image) => image.value === e.target?.value)

    setImageState({
      ...imageState,
      image: {
        url: e.target?.value,
        previewUrl: e.target?.value === 'default' ? existingImages[0]?.value : undefined,
        width: selectedImage?.additionalDetails?.width,
        height: selectedImage?.additionalDetails?.height,
      },
    })
  }

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      {isLoading ? (
        <div className="d-flex justify-content-center text-danger">Loading...</div>
      ) : (
        <>
          <h6>Image</h6>
          <div className="row">
            {/* Image Type Step */}
            <div className="col-12">
              <label htmlFor="imageType-select">
                Do you want to use an existing image or a custom image?
              </label>
              <select
                className="form-control d-block"
                id="imageType-select"
                defaultValue={imageState?.type ?? ''}
                onChange={(e) => {
                  handleImageTypeChange(e)
                }}
              >
                <option value="">Select an option</option>
                {imageTypeOptions?.map((type, index) => (
                  <option key={`${type?.value}-${index}`} value={type?.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            {imageState?.type &&
              (imageState?.type === 'default' ? (
                <div className="col-12">
                  <div className="row mt-3">
                    {/* Image Category Step */}
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="imageExistingCategory-select">
                        Please select a category to choose from
                      </label>
                      <select
                        className="form-control d-block"
                        id="imageExistingCategory-select"
                        defaultValue={imageState?.existingCategory ?? ''}
                        onChange={(e) => handleImageExistingCategoryChange(e)}
                      >
                        <option value="">Select a category</option>
                        {getExistingCategoryOptions(brands, templateData)?.map((type, index) => (
                          <option key={`${type?.value}-${index}`} value={type?.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    {(imageState?.existingCategory === 'manufacturer' ||
                      imageState?.existingCategory === 'model') && (
                      <>
                        {/* Manufacturer step */}
                        <div className="col-12 col-md-6 mb-3">
                          {manufacturers?.length > 0 ? (
                            <>
                              <label htmlFor="imageExistingFilters-manufacturer-select">
                                Please select a manufacturer
                              </label>
                              <select
                                className="form-control d-block"
                                id="imageExistingFilters-manufacturer-select"
                                defaultValue={imageState?.existingFilters?.manufacturer ?? ''}
                                onChange={(e) => handleImageExistingFiltersManufacturerChange(e)}
                              >
                                <option value="">Select a manufacturer</option>
                                {manufacturers?.map((type, index) => (
                                  <option key={`${type?.value}-${index}`} value={type?.value}>
                                    {type.label}
                                  </option>
                                ))}
                              </select>
                            </>
                          ) : (
                            <span>Sorry, your website is not linked to any manufacturers</span>
                          )}
                        </div>
                        {/* Model Step */}
                        {imageState?.existingCategory === 'model' &&
                          imageState?.existingFilters?.manufacturer &&
                          imageState?.existingFilters?.manufacturer !== '' && (
                            <div className="col-12 col-md-6 mb-3">
                              {models?.length > 0 ? (
                                <>
                                  <label htmlFor="imageExistingFilters-model-select">
                                    Please select a model
                                  </label>
                                  <select
                                    className="form-control d-block"
                                    id="imageExistingFilters-model-select"
                                    defaultValue={imageState?.existingFilters?.model ?? ''}
                                    onChange={(e) => handleImageExistingFiltersModelChange(e)}
                                  >
                                    <option value="">Select a model</option>
                                    {models?.map((type, index) => (
                                      <option key={`${type?.value}-${index}`} value={type?.value}>
                                        {type.label}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <span>Sorry, that manufacturer has no models</span>
                              )}
                            </div>
                          )}
                      </>
                    )}
                    {/* Image Select Step */}
                    {imageState?.existingCategory === 'default' ||
                    (imageState?.existingCategory === 'manufacturer' &&
                      imageState?.existingFilters?.manufacturer !== undefined &&
                      imageState?.existingFilters?.manufacturer !== '') ||
                    (imageState?.existingCategory === 'model' &&
                      imageState?.existingFilters?.model !== undefined &&
                      imageState?.existingFilters?.model !== '') ? (
                      <>
                        <div className="col-12 col-md-6 mb-3">
                          {existingImages?.length > 0 ? (
                            <>
                              <label htmlFor="image-select">Please select an image</label>
                              <select
                                className="form-control d-block"
                                id="image-select"
                                defaultValue={imageState?.image?.url ?? ''}
                                onChange={(e) => handleExistingImageChange(e)}
                              >
                                <option value="">Select an image</option>
                                {/* // ! ONLY allow this option on brand/model pages */}
                                {(templateData.manufacturer || templateData.model) &&
                                  imageState?.existingCategory !== 'default' && (
                                    <option value="default">Keep up-to-date</option>
                                  )}
                                {existingImages?.map((type, index) => (
                                  <option key={`${type?.value}-${index}`} value={type?.value}>
                                    {type.label}
                                  </option>
                                ))}
                              </select>
                            </>
                          ) : (
                            <span>Sorry, there are no images available</span>
                          )}
                        </div>
                        {/* Image Preview */}
                        <div className="col-12 mt-2 mb-1">
                          <ImageBannerPreview
                            state={state}
                            customImageState={imageState}
                            showOverlay={false}
                            showContent={false}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                // Custom Image Step
                <div className="col-12 mt-3">
                  <LabeledFileInput
                    label="Image"
                    item={imageState}
                    itemName="image"
                    file={imageState?.image}
                    updateItem={setImageState}
                    imageEndpoint={imageEndpoint}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default ImageSelectStep
