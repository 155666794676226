import { useCallback, useState } from 'react'

import { BlockAPI } from '@editorjs/editorjs'
import { ColorPicker } from 'primereact/colorpicker'
import { Slider } from 'primereact/slider'
import { createRoot } from 'react-dom/client'

import { unique } from '../../../entries/utils'
import { LabeledCheckbox, LabeledNumberInput, LabeledSelect, bootstrapColours } from '../../common'
import Dialog from '../../common/Dialog'
import { renderEditSettingsButton, renderHiddenModalButton } from '../../common/commonUtils'
import type { DefaultConfigProps, NodesProps, ToolboxProps } from '../../types/toolData.type'
import { handleColorType } from '../FinanceCalculatorTool/utils'

const RenderedFormComponent = ({ data, onDataChange, uniqueId }) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState(data)

  /**
   * Update the common fields such as fullWidth
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
      })
      onDataChange({ ...item })
    },
    [onDataChange]
  )

  return (
    <>
      {state?.usePageTitle ? (
        <div style={{ paddingTop: 18, paddingBottom: 26 }}>
          <hr
            className="my-0"
            style={{
              borderTopColor: 'var(--primary)',
              borderTopWidth: 8,
              borderRadius: 5,
              width: 100,
            }}
          />
        </div>
      ) : (
        <div className="py-3">
          <hr
            className="my-0"
            style={{
              border: 'none',
              backgroundColor: handleColorType(state.color),
              height: state.height ?? 10,
              borderRadius: `calc(${state.borderRadius}rem / 10)`,
              opacity: `${state.opacity * 10}%`,
            }}
          />
        </div>
      )}
      <Dialog title="Horizontal Rule" show={show} closeClickHandler={() => setShow(false)}>
        <div className="row">
          <div className="col-12">
            <div className="pl-4">
              <LabeledCheckbox
                item={state}
                itemName="usePageTitle"
                label="Use page title styling"
                updateItem={syncedStateUpdate}
              />
            </div>
          </div>
          {!state?.usePageTitle && (
            <>
              <div className="col-12 col-md-6">
                {/* @ts-ignore */}
                <LabeledNumberInput
                  item={state}
                  itemName="height"
                  label="Height (px)"
                  placeholder="Line height..."
                  updateItem={syncedStateUpdate}
                  min={0}
                />
              </div>
              <div className="col-12 col-md-6">
                <label>Border Radius</label>
                <div className="d-flex align-items-center mb-3">
                  <div className="ml-2 mr-3 flex-grow-1 align-self-center">
                    <Slider
                      value={state.borderRadius}
                      onChange={(e) => syncedStateUpdate({ ...state, borderRadius: e.value })}
                      step={1}
                      min={0}
                      max={10}
                    />
                  </div>
                  <div className="d-flex align-items-center small font-weight-bold">
                    {state.borderRadius}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <LabeledSelect
                  controlled={false}
                  item={state.color}
                  itemName="colorType"
                  label="Color"
                  customOnChange={(e) =>
                    syncedStateUpdate({
                      ...state,
                      color: {
                        ...state.color,
                        colorType: e.target.value,
                      },
                    })
                  }
                  options={[...Array.from(bootstrapColours), 'custom']}
                />
              </div>
              {state.color.colorType === 'custom' && (
                <div className="col-12 col-md-6 d-flex align-items-end mb-3">
                  <ColorPicker
                    value={state.color.customColor}
                    onChange={(e) =>
                      syncedStateUpdate({
                        ...state,
                        color: { ...state.color, customColor: e.value },
                      })
                    }
                    appendTo="self"
                    pt={{
                      input: () => ({
                        style: {
                          height: 38,
                          width: 38,
                        },
                      }),
                    }}
                  />
                  <input
                    placeholder="Enter a color hexcode"
                    className="form-control ml-1"
                    value={state.color.customColor}
                    onChange={(e) =>
                      syncedStateUpdate({
                        ...state,
                        color: {
                          ...state.color,
                          customColor: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              )}
              <div className="col-12 col-md-6">
                <label>Opacity</label>
                <div className="d-flex align-items-center mb-3">
                  <div className="ml-2 mr-3 flex-grow-1 align-self-center">
                    <Slider
                      value={state.opacity}
                      onChange={(e) => syncedStateUpdate({ ...state, opacity: e.value })}
                      step={1}
                      min={0}
                      max={10}
                    />
                  </div>
                  <div className="d-flex align-items-center small font-weight-bold">
                    {state.opacity * 10}%
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class HorizontalRuleTool {
  private config: DefaultConfigProps
  private blockAPI: BlockAPI
  private uniqueId: string
  private data: {
    height: number
    color: string
    borderRadius: number
    opacity: number
  }
  private nodes: NodesProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Horizontal Rule',
      icon: '<i class="fa-solid fa-horizontal-rule"/>',
    }
  }

  constructor({ data, config, block }) {
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    let defaultData = {
      usePageTitle: false,
      height: 1,
      borderRadius: 5,
      opacity: 1,
      color: {
        colorType: 'primary',
        customColor: '000000',
      },
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData: object) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedFormComponent
        onDataChange={onDataChange}
        data={this.data}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default HorizontalRuleTool
