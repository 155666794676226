import { useEffect, useState } from 'react'

import { LabeledColorInput, LabeledNumberInput } from '../../../common'
import ImageBannerPreview from '../ImageBannerPreview'

// Overlay Active options
const overlayActiveOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]

/**
 * The OverlayStep component controls the overlay of the Banner.
 * @param {*} stepIndex the index of this step
 * @param {*} isActive whether this step is active
 * @param {*} isModalOpen whether the modal is open
 * @param {*} activeIndexState an object tracking the previous and current active indexes
 * @param {*} state the current state of the ImageBanner instance
 * @param {*} syncedStateUpdate function to sync the state of the ImageBanner instance with the saved data
 * @param {*} onDataChange function to handle saving data changes
 * @param {*} getUpdatedData function to get the updated saved data
 * @returns The Overlay step with all required fields and previews
 */
const OverlayStep = ({
  stepIndex,
  isActive,
  isModalOpen,
  activeIndexState,
  state,
  syncedStateUpdate,
  onDataChange,
  getUpdatedData,
}) => {
  // Handle component states
  const [overlayState, setOverlayState] = useState(state?.overlay)

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()
      syncedStateUpdate({ ...updatedData, overlay: { ...updatedData?.overlay, ...overlayState } })
    }
  }, [isActive, isModalOpen])

  // Save data when overlay state is updated
  useEffect(() => {
    onDataChange({
      ...state,
      overlay: overlayState,
    })
  }, [overlayState])

  const handleOverlayActiveChange = (e) => {
    setOverlayState({
      ...overlayState,
      active: e.target.value,
    })
  }

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      <h6>Overlay</h6>
      <div className="row">
        {/* Overlay On/Off Step */}
        <div className="col-12 mb-3">
          <label htmlFor="bannerLayout-select">Do you want an overlay?</label>
          <select
            className="form-control d-block"
            id="bannerLayout-select"
            defaultValue={overlayState?.active ?? undefined}
            onChange={(e) => {
              handleOverlayActiveChange(e)
            }}
          >
            <option value={undefined}>Select an option</option>
            {overlayActiveOptions?.map((type, index) => (
              <option key={`${type?.value}-${index}`} value={type?.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
        {overlayState?.active === 'true' && (
          <>
            <div className="col-12 col-md-6">
              <LabeledColorInput
                item={overlayState}
                itemName="color"
                label="Overlay Colour"
                color={overlayState.color}
                updateItem={setOverlayState}
              />
            </div>
            <div className="col-12 col-md-6">
              <LabeledNumberInput
                item={overlayState}
                itemName="opacity"
                label="Overlay Opacity"
                placeholder="Value from 1 - 100"
                updateItem={setOverlayState}
                min={1}
                max={100}
              />
            </div>
          </>
        )}
        {/* Image Preview */}
        <div className="col-12 mt-2 mb-1">
          <ImageBannerPreview state={state} customOverlayState={overlayState} showContent={false} />
        </div>
      </div>
    </div>
  )
}

export default OverlayStep
