// EditorJS Tools
// Custom Tools
import { ToolConstructable, ToolSettings } from '@editorjs/editorjs'
import RawTool from '@editorjs/raw'

import {
  getAlternatingColumnsConfig,
  getButtonsConfig,
  getCardsConfig,
  getColumnsConfig,
  getCustomComponentConfig,
  getFaqConfig,
  getHeaderConfig,
  getHorizontalRuleConfig,
  getImageBannerConfig,
  getImageConfig,
  getInlineTools,
  getListConfig,
  getParagraphConfig,
  getReviewsConfig,
  getTableConfig,
  getTunes,
} from '../../editorConfig'
import { CarsTool } from '../../tools'
import { LocationProps } from '../../types'
import { getCarsViewerFilters } from '../templateUtils'

/**
 * Gets the available editor tools for the Search template
 *
 * @param tunes - a function that returns the tunes object
 * @param saved - a function that is called when the editor is saved
 * @param imageUrl - the image upload url
 * @returns An object containing the available editor tools
 */
export function getSearchTemplateEditorTools(
  saved: () => void,
  imageUrl: string,
  brands: any[],
  locations: LocationProps[],
  templateData: any,
  isAdmin: boolean
): {
  [toolName: string]: ToolConstructable | ToolSettings
} {
  return {
    header: getHeaderConfig(),
    paragraph: getParagraphConfig(),
    image: getImageConfig(imageUrl),
    imageBanner: getImageBannerConfig(brands, templateData, imageUrl, saved),
    list: getListConfig(),
    table: getTableConfig(),
    // This is custom for the Search template
    cars: {
      class: CarsTool,
      config: {
        templateType: 'search',
        save: saved,
      },
      tunes: [],
    },
    buttonsTool: getButtonsConfig(saved),
    horizontalRule: getHorizontalRuleConfig(saved),
    reviews: getReviewsConfig(locations, saved),
    cardsTool: getCardsConfig(imageUrl, saved),
    columns: getColumnsConfig(imageUrl, locations, saved, true, undefined, 'search'),
    alternatingColumns: getAlternatingColumnsConfig(imageUrl, locations, saved, true),
    faq: getFaqConfig(imageUrl, locations, saved, true),
    customComponent: getCustomComponentConfig(templateData, isAdmin, imageUrl, saved),
    raw: RawTool,
    ...getInlineTools(),
    ...getTunes(saved),
  }
}

/**
 * Handles event changes for the blocks
 * @param api - the editor API
 * @param event - the event that triggered the Editor's onChange event
 * @returns An array of alerts to display to the user
 */
function handleBlockEventChanges(api: any, event: any) {
  const alertList = []

  // Handle block changes for the Cars block
  if (event.type === 'block-removed' && event.detail.target.name === 'cars') {
    alertList.push('Search pages must have a cars component. This block cannot be deleted.')
    api.blocks.insert(
      'cars',
      {
        searchState: {
          configure: {
            filters: getCarsViewerFilters(),
          },
          menu: {},
          page: 1,
          hitsPerPage: 18,
          refinementList: {},
        },
        multiSelect: true,
        dateFilter: null,
        hideButton: true,
        hideSearch: true,
        buttonColor: 'light',
      },
      {}
    )
  }

  return alertList
}

/**
 * Handles the search on ready events
 * @param editor - the editor instance
 */
export function handleSearchOnReadyEvents() {
  // Always hide CarsTool button
  const carsToolButtons = Array.from(
    document.querySelectorAll('.ce-popover-item[data-item-name="cars"]')
  ) as HTMLElement[]
  carsToolButtons.forEach((button) => (button.style.display = 'none'))
}

/**
 * Handles the search on change events
 * @param api - the editor API
 * @param event - the event that triggered the function
 */
export function handleSearchOnChangeEvents(api: any, event: any) {
  const tuneMenuButton = document.querySelector('.ce-toolbar__settings-btn')

  // Handle Tune menu settings
  tuneMenuButton.addEventListener('click', () => {
    const deleteButtons = Array.from(
      document.querySelectorAll('.ce-popover-item[data-item-name="delete"]')
    ) as HTMLElement[]

    // Hide delete for the Cars block
    if (api.blocks.getBlockByIndex(api.blocks.getCurrentBlockIndex()).name === 'cars') {
      deleteButtons.forEach((button) => (button.style.display = 'none'))
    }
  })

  // Handle onChange events
  // For multiple events, check if the event is an array
  if (Array.isArray(event)) {
    // Track alerts for multiple events
    const alertList = []

    // Handle each event individually
    event.forEach((e) => {
      if (
        // Handle block change or removal for Cars block
        (e.type === 'block-removed' || e.type === 'block-changed') &&
        e.detail.target.name === 'cars'
      ) {
        alertList.push(...handleBlockEventChanges(api, e))
      }
    })

    // Display alerts if there are any issues with the changes
    if (alertList.length > 0) {
      alert(
        'Sorry, there were the following issues with your changes:\n\n' +
          [...Array.from(new Set(alertList))].join('\n\n')
      )
    }

    // For single events, handle the event
  } else {
    const alertList = handleBlockEventChanges(api, event)
    // Display alerts if there are any issues with the changes
    if (alertList.length > 0) {
      alert('Sorry, there were the following issues with your changes:\n\n' + alertList.join('\n'))
    }
  }
}
