import { unique } from '../../../entries/utils'
import {
  Car,
  Check,
  Download,
  Finance,
  Phone,
  Quote,
  Search,
  Service,
  TestDrive,
} from '../../common/Icons'

export const acceptedIcons = [
  {
    name: 'Test Drive',
    icon: TestDrive,
  },
  {
    name: 'Service',
    icon: Service,
  },
  {
    name: 'Finance',
    icon: Finance,
  },
  {
    name: 'Sell Your Car',
    icon: Car,
  },
  {
    name: 'Contact',
    icon: Phone,
  },
  {
    name: 'Download',
    icon: Download,
  },
  {
    name: 'Search',
    icon: Search,
  },
  {
    name: 'Quote',
    icon: Quote,
  },
  {
    name: 'Check',
    icon: () => <Check size="100%" />,
  },
]

export const defaultSetup = [
  { id: unique(), icon: 'Test Drive', text: 'Test Drive', href: '/book-test-drive' },
  { id: unique(), icon: 'Service', text: 'Service', href: '/service' },
  { id: unique(), icon: 'Finance', text: 'Finance', href: '/finance-pre-approval' },
  { id: unique(), icon: 'Sell Your Car', text: 'Sell Your Car', href: '/sell-my-car' },
]

export const defaultItem = () => {
  return {
    id: unique(),
    icon: 'Contact',
    text: 'Contact Us',
    href: '/contact',
  }
}
