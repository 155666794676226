import { generateRandomId, toSentence } from '../../common/commonUtils'
import { getFormTemplate } from '../../tools/FormBuilderTool/FormBuilderTemplates'
import { WebsiteDetailsProps } from '../../types'
import { getErrorMessage, getPrimaryLocation } from '../templateUtils'
import { handleNewOrUsedCarFAQ } from './financeUtils'

export function financeTemplate(websiteDetails: WebsiteDetailsProps) {
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const primaryLocation = getPrimaryLocation(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'default',
          existingCategory: 'default',
          existingFilters: {},
          image: {
            url: 'https://res.cloudinary.com/total-dealer/image/upload/v1644660263/td_next/finance-pre-approval_tcigpv',
          },
        },
        layout: {
          type: 'default',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 5,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: 'Get Finance!',
        level: 2,
      },
      tunes: {
        alignment: {
          alignment: 'left',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `Leading Provider of Car Finance in ${primaryLocation?.address?.city} ${primaryLocation?.address?.state}`,
        level: 5,
      },
      tunes: {
        alignment: {
          alignment: 'left',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'secondary',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 2,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Flexible and convenient',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Tailored finance options',
            href: '',
          },
        ],
        type: 'minimal',
        style: 'fill',
        buttonsPerRow: '4',
      },
      tunes: {
        alignment: {
          alignment: 'left',
        },
        container: {
          contain: false,
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        type: 'minimal',
        buttonsPerRow: '4',
        style: 'fill',
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Does not impact credit score',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Unmatched network of partners',
            href: '',
          },
        ],
      },
      tunes: {
        alignment: {
          alignment: 'left',
        },
        container: {
          contain: false,
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'formBuilder',
      data: getFormTemplate('finance'),
      tunes: {
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        margin: {
          spacingTop: 3,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 5,
          paddingBottom: 5,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'faq',
      data: {
        heading: 'Frequently Asked Questions',
        subheading: '',
        questions: [
          {
            time: 1713831147907,
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: {
                  text: 'How do I apply for a Car Loan?',
                  level: 2,
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: 'Finding a car loan can sometimes be overwhelming! With {website.name}, finding a car loan is quick, fast and easy! We have multiple different finance providers who we&nbsp;work with to ensure that we are providing you with the best possible finance rate&nbsp;and finance option to suit your needs. To apply, simply fill out the form above and&nbsp;that will start your finance journey.',
                },
              },
            ],
            version: '2.29.0',
          },
          {
            time: 1713831147908,
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: {
                  text: 'How do interest rates work with Car Finance?',
                  level: 2,
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: "Car finance interest rates are very similar to finance you will get with a home loan. Additionally, there are two different types of car loan interest rates: fixed and variable. Here's how they work:",
                },
              },
              {
                id: generateRandomId(10),
                type: 'list',
                data: {
                  style: 'unordered',
                  items: [
                    '<b>Fixed Interest:</b>&nbsp;A fixed rate loan has the same interest rate for the entirety of the borrowing period, allowing you to get a clear view of what your repayments could look like.',
                    "<b>Variable Interest:</b>&nbsp;This means that the interest rate for your car loan could either increase or decrease at your lender's discretion, and therefore increase or decrease your interest repayments accordingly.",
                  ],
                },
              },
            ],
            version: '2.29.0',
          },
          {
            time: 1713831147908,
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: {
                  text: 'What is a Balloon Payment?',
                  level: 2,
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: 'A "balloon payment" is a once-off lump sum that is paid at the end of a car loan, covering off the outstanding balance.',
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: 'This allows you to repay only part of the principal of your loan over its term, reducing your monthly repayments in exchange for owing the lender a lump sum at the end of the loan term.',
                },
              },
            ],
            version: '2.29.0',
          },
          {
            time: 1713831147908,
            blocks: handleNewOrUsedCarFAQ(websiteDetails),
            version: '2.29.0',
          },
        ],
      },
      tunes: {
        container: {
          contain: false,
        },
        margin: {
          spacingTop: 5,
          spacingBottom: 5,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
  ]
}
