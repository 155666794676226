import { FaPhone, MapMarkerAlt, MapPlaceholder, Time } from '../../common/Icons'

const Detail = ({ Icon, children }) => (
  <div className="d-flex mb-2">
    <div className="mr-3 text-primary">
      <Icon />
    </div>
    {children}
  </div>
)

const LocationInfo = ({ location }) => (
  <div className="text-secondary text-left mt-auto">
    <Detail Icon={MapMarkerAlt}>
      <div className="text-primary">
        {location.address.street}, <br />
        {[location.address.city, location.address.state, location.address.postcode].join(', ')}
      </div>
    </Detail>
    <Detail Icon={FaPhone}>
      <div className="text-primary">Phone Number</div>
    </Detail>
    <Detail Icon={Time}>
      <div className="d-flex flex-column">
        <div>
          <span className="font-weight-bold">Mon-Fri:</span> Opening Hours
        </div>
        <div>
          <span className="font-weight-bold">Sat:</span> Opening Hours
        </div>
        <div>
          <span className="font-weight-bold">Sun:</span> Opening Hours
        </div>
      </div>
    </Detail>
  </div>
)

export const SingleLocation = ({ location }) => (
  <div className="bg-light border-top">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-6 bg-white">
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{ width: '100%', aspectRatio: 1 / 1 }}
          >
            <MapPlaceholder />
          </div>
        </div>
        <div className="col-12 col-md-10 col-lg-6 d-flex justify-content-center align-items-center bg-white">
          <div className="d-flex flex-column p-3">
            <h4 className="mb-3">{location.name}</h4>
            <LocationInfo location={location} />
            <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
              <button className="btn btn-primary">Contact Us</button>

              <button className="btn btn-primary ml-0 ml-md-2">Get Directions</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const LocationCards = ({ locations }) => (
  <div className="container">
    <div className="row justify-content-center">
      {locations.map((location) => (
        <div
          className={
            (locations.length > 3
              ? 'col-xl-3'
              : locations.length >= 3
                ? 'col-lg-4'
                : locations.length < 3
                  ? 'col-lg-5'
                  : '') + ' col-md-6 mb-4'
          }
          key={location.id}
        >
          <div
            className="d-flex flex-column h-100"
            style={{
              boxShadow:
                '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center p-3 border-bottom"
              style={{ width: '100%', height: 150 }}
            >
              <MapPlaceholder />
            </div>
            <div className="p-3 flex-grow-1">
              <div className="d-flex h-100 flex-column justify-content-end">
                <h5 className="text-center mb-3">{location.name}</h5>
                <LocationInfo location={location} />
                <button className="btn btn-primary btn-block mt-3">Contact Us</button>
                <button className="btn btn-primary btn-block">Get Directions</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)
