import React, { useState } from 'react'

import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { Configure, InstantSearch } from 'react-instantsearch'

import MenuSelect from '../../../entries/MenuSelect'
import Dialog from '../../common/Dialog'
import RefinementListSelect from '../../common/RefinementListSelect'
import { renderHiddenModalButton } from '../../common/commonUtils'
import SpecialOffersPreview from './SpecialOffersPreview'

export const indexName = `Offer_${process.env.RAILS_ENV}`

const MeiliSearch = ({ onDataChange, data, uniqueId }) => {
  const [show, setShow] = useState(false)
  const [searchState, setSearchState] = useState(data.searchState || {})

  const meilisearchHost = document.getElementById('meilisearch-host').getAttribute('value')
  const meilisearchApiKey = document.getElementById('meilisearch-api-key').getAttribute('value')

  const { searchClient } = instantMeiliSearch(meilisearchHost, meilisearchApiKey)

  function onSearchStateChange(updatedSearchState) {
    onDataChange({
      searchState: updatedSearchState[Object.keys(updatedSearchState)[0]],
    })
    setSearchState(updatedSearchState)
  }

  let filters = ''

  return (
    <InstantSearch
      indexName={`Offer_${process.env.RAILS_ENV}`}
      searchClient={searchClient}
      onStateChange={({ uiState }) => {
        onSearchStateChange(uiState)
      }}
      initialUiState={searchState}
    >
      <Configure filters={filters} />
      <SpecialOffersPreview />
      <Dialog
        title="Special Offers"
        show={show}
        closeClickHandler={() => setShow(false)}
        forceMount
      >
        <div className="border-bottom pb-3">
          <div className="row">
            <div className="col-12 text-secondary">
              Apply filters to customise the special offers displayed in this block.
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <MenuSelect attribute="manufacturer.name" seeAllOption="All Makes" limit={20} />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <MenuSelect attribute="model.name" seeAllOption="All Models" />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <MenuSelect attribute="model.body" seeAllOption="All Bodies" />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <MenuSelect attribute="category" seeAllOption="All Categories" />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <RefinementListSelect attribute="state" />
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </InstantSearch>
  )
}

const SpecialOffersViewer = ({ onDataChange, data, uniqueId }) => {
  // The JSON structure in the page templates for instant search is still from version 6
  // so we apply the v7 format to it to keep Meilisearch happy but we have to save it back to
  // the v6 format
  const convertedData = {
    ...data,
    searchState: {
      [`Offer_${window.environment}`]: data.searchState,
    },
  }

  return <MeiliSearch onDataChange={onDataChange} data={convertedData} uniqueId={uniqueId} />
}

export default SpecialOffersViewer
