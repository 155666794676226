import type { PropsWithChildren } from 'react'

import {
  getAlignment,
  getClassName,
  getColor,
  getMargin,
  getPadding,
  getScreenSize,
} from '../editorJsUtils'

const TunesContainer = ({
  tunes,
  children,
}: {
  tunes: any // TODO
} & PropsWithChildren) => {
  const screenSize = getScreenSize(tunes?.screensize?.screenSize)
  const className = getClassName(tunes?.classname?.className)
  const alignment = getAlignment(tunes?.alignment?.alignment)
  const margin = getMargin(tunes?.margin?.spacingTop, tunes?.margin?.spacingBottom)
  const padding = getPadding(tunes?.padding?.paddingTop, tunes?.padding?.paddingBottom)
  const backgroundColor = getColor(
    tunes?.backgroundColor?.backgroundColor,
    tunes?.backgroundColor?.customBackgroundColor
  )
  const textColor = getColor(tunes?.textColor?.textColor, tunes?.textColor?.customTextColor)

  return (
    <div
      className={`w-100 ${screenSize ?? ''} ${className ?? ''} ${alignment ?? ''} ${margin ?? ''} ${
        padding ?? ''
      }`}
      style={{ background: backgroundColor ?? '', color: textColor ?? '' }}
    >
      {children}
    </div>
  )
}

/**
 * This is a core UI component for our Editor blocks collection.
 * It is used to wrap primitive elements such as headings, paragraphs, lists, and columns.
 *
 * @param {boolean} props.isInRow - Check if the component is already contained within a row.
 * @param {boolean} props.isInRow - Check if the component is an image.
 * @param {Object} props.tunes.alignment - An Editor.js tune that sets the text alignment
 * @param {Object} props.tunes.classname - An Editor.js tune that provides a custom className.
 * @param {boolean} props.tunes.container - An Editor.js tune that sets whether to contain the element in our standard Bootstrap container
 * @param {ReactNode} props.children - The component that is be wrapped by this container.
 *
 */
const Container = ({
  tunes,
  isInRow,
  isImage,
  isText,
  children,
}: {
  tunes: any // TODO
  isInRow?: boolean
  isImage?: boolean
  isText?: boolean
} & PropsWithChildren) => {
  const contain = tunes?.container?.contain

  // @ts-ignore Remove empty lines
  if (children?.type === 'p' && children?.props?.dangerouslySetInnerHTML?.__html === '') {
    return null
  }

  const defaultContainer = !isInRow && !isImage ? 'container' : ''

  return (
    <TunesContainer tunes={tunes}>
      {contain ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-9">{children}</div>
          </div>
        </div>
      ) : isText ? (
        <div className={defaultContainer}>
          <div className="row justify-content-center">
            <div className="col-12">{children}</div>
          </div>
        </div>
      ) : (
        <div className={defaultContainer}>{children}</div>
      )}
    </TunesContainer>
  )
}

export default Container
