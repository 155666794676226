import { useState } from 'react'

import { API, BlockAPI } from '@editorjs/editorjs'
import { Button } from 'primereact/button'
import { ScrollPanel } from 'primereact/scrollpanel'
import { ScrollTop } from 'primereact/scrolltop'
import { SplitButton } from 'primereact/splitbutton'
import { ToggleButton } from 'primereact/togglebutton'
import { createRoot } from 'react-dom/client'

import { LabeledInput } from '../../common'
import Dialog from '../../common/Dialog'
import type { DefaultConfigProps, NodesProps, ToolboxProps } from '../../types/toolData.type'
import { PropsProvider, useCustomProps } from './PropsController'
import { CustomProps } from './PropsTools'
import { CCPropsData, OPTIONS_TREE, fetchComponentOptions } from './customComponentUtils'

const RenderedFormComponent = ({ editorApi }) => {
  const [show, setShow] = useState(false)
  const {
    addNewField,
    data,
    isAdmin,
    setCustomComponentId,
    state,
    templateType,
    updateId,
    showRaw,
    updateTab,
  } = useCustomProps()

  const splitItems = OPTIONS_TREE.map(({ label, icon, key }) => ({
    label,
    icon,
    command: () => addNewField(key),
  }))

  const DialogFooter = () => (
    <>
      <ToggleButton
        onLabel="Show UI"
        offLabel="Show Raw JSON"
        onIcon="fa-solid fa-pencil"
        offIcon="fa-solid fa-file-code"
        checked={showRaw}
        onChange={updateTab}
      />
      <SplitButton
        style={{ backgroundColor: 'var(--primary)' }}
        className="ml-2"
        icon="fa-solid fa-plus"
        label="Add Another Input"
        onClick={() => addNewField()}
        model={splitItems}
        menuButtonClassName="px-2"
      />
      {/* <Button style={{ backgroundColor: 'var(--primary)' }} className="ml-2" icon="fa-solid fa-plus" label="Add Another Input" onClick={addNewField} /> */}
    </>
  )

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="border rounded shadow-sm pt-3 px-3 bg-white text-dark">
            <div className="d-flex flex-column text-center mb-2 mx-0 mx-lg-5">
              <div>
                <p className="badge badge-warning mb-1">Warning</p>
              </div>
              <span className="small">
                This component is used to implement custom components that aren't customisable in
                the editor. All tune customisations are available, but each component may only be
                able to utilise specific ones. Please contact customer support if you are having any
                issues and need help.
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="unique-component-id-select">Component</label>
              <select
                id="unique-component-id-select"
                className="form-control"
                defaultValue={data.uniqueComponentId ?? 'placeholder'}
                onChange={updateId}
              >
                <option disabled value="placeholder">
                  Select a component
                </option>
                {fetchComponentOptions(templateType).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <option value="custom">Custom</option>
              </select>
            </div>
            {state?.uniqueComponentId === 'custom' && (
              <LabeledInput
                controlled={false}
                item={state}
                itemName="customComponentId"
                label="Custom Component"
                placeholder="Enter the custom component ID"
                customOnChange={setCustomComponentId}
              />
            )}
            {isAdmin && state?.uniqueComponentId === 'custom' && (
              <>
                <Button
                  className="btn btn-outline-secondary my-2 w-100 custom-component-props-button"
                  onClick={() => setShow(true)}
                >
                  {`${state.customComponentId ?? 'Custom Component'} Inputs`}
                </Button>
                <Dialog
                  title={`${state.customComponentId ?? 'Custom Component'} Inputs`}
                  show={show}
                  customMaxWidth="85vw"
                  closeClickHandler={() => setShow(false)}
                  footer={<DialogFooter />}
                >
                  <ScrollPanel style={{ width: '100%', height: '100%' }}>
                    <CustomProps editorApi={editorApi} show={show} />
                    <ScrollTop target="parent" threshold={100} className="bg-primary ml-auto" />
                  </ScrollPanel>
                </Dialog>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

class CustomComponentTool {
  private config: DefaultConfigProps & { isAdmin: boolean }
  private editorApi: API
  private blockAPI: BlockAPI
  private data: CCPropsData
  private CSS: {
    wrapper: string
  }
  private nodes: NodesProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Custom Component',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-alert">
          <circle cx="12" cy="12" r="10"/><line x1="12" x2="12" y1="8" y2="12"/>
          <line x1="12" x2="12.01" y1="16" y2="16"/>
        </svg>`,
    }
  }

  constructor({ data, config, api, block }) {
    this.config = config
    this.editorApi = api
    this.blockAPI = block

    let defaultData = {
      uniqueComponentId: undefined,
      customComponentId: undefined,
      // existing custom components will be undefined
      customComponentProps: undefined,
      rawProps: undefined,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.CSS = {
      wrapper: 'custom-component-tool',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData: object) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <PropsProvider
        onDataChange={onDataChange}
        data={this.data}
        templateType={this.config.templateType}
        isAdmin={this.config.isAdmin}
        // Used for embedded actions
        editorApi={this.editorApi}
        // @ts-ignore
        imageEndpoint={this.config.imageUrl}
      >
        <RenderedFormComponent editorApi={this.editorApi} />
      </PropsProvider>
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default CustomComponentTool
