import { default as React, useEffect, useState } from 'react'

import { Message } from 'primereact/message'
import { createRoot } from 'react-dom/client'

import { unique } from '../../../entries/utils'
import Dialog from '../../common/Dialog'
import { FormGroup } from '../../common/FormGroup'
import { Info } from '../../common/Icons'
import Tooltip from '../../common/Tooltip'
import {
  capitalize,
  removeDuplicateLocations,
  renderEditSettingsButton,
  renderHiddenModalButton,
} from '../../common/commonUtils'
import { LocationCards, SingleLocation } from './LocationPreviews'
import {
  applyFineTunedLocationFilters,
  getManufacturerLocationOptions,
  getSubtypeOptions,
  updateActiveLocations,
} from './locationsUtils'

const RenderedLocationsComponent = ({
  onDataChange,
  data,
  locations,
  primaryLocationId,
  uniqueId,
}) => {
  // Modal state management
  const [show, setShow] = useState(false)

  // Filter out duplicate locations and sort by position
  // Infinity will position undefined location positions at the end
  const filteredLocationData = locations
    ? removeDuplicateLocations(locations).sort(
        (a, b) => (a.position || Infinity) - (b.position || Infinity)
      )
    : []
  // Extract unique manufacturer options (from the locations data)
  const locationManufacturerOptions = getManufacturerLocationOptions(filteredLocationData)
  // Extract unique subtype options
  const subtypeSelectOptions = getSubtypeOptions(filteredLocationData)

  // Data state management
  const [currentData, setCurrentData] = useState(data)
  const [activeLocations, setActiveLocations] = useState(filteredLocationData)
  const [previewLocations, setPreviewLocations] = useState(
    applyFineTunedLocationFilters(filteredLocationData, data.filteredLocations)
  )

  // Update the active locations and preview locations when the data changes
  useEffect(() => {
    updateActiveLocations(
      currentData,
      filteredLocationData,
      primaryLocationId,
      onDataChange,
      setActiveLocations,
      setPreviewLocations
    )
  }, [currentData])

  // Function to handle selection changes
  function handleFilterChange(updatedValues) {
    const updatedData = {
      filter: currentData.filter,
      manufacturer: currentData.manufacturer ?? false,
      subtype: currentData.subtype ?? false,
      filteredLocations: [],
      ...updatedValues,
    }
    // Update the state and saved data
    onDataChange(updatedData)
    setCurrentData(updatedData)
  }

  return (
    <>
      {previewLocations.length < 1 ? (
        <Message className="w-100 my-3" severity="warn" text="No active locations" />
      ) : previewLocations.length > 1 ? (
        <LocationCards locations={previewLocations} />
      ) : (
        <SingleLocation location={previewLocations[0]} />
      )}
      <Dialog title="Location" show={show} closeClickHandler={() => setShow(false)}>
        <Message
          severity="info"
          className="w-100 mb-3 d-block"
          text="If a location is not appearing as expected under certain filters, please check the location's Location type, Manufacturer ids, and Location subtype and ensure they are correct."
        />
        <div className="pt-3 border-top">
          <div className="row">
            <div className="col-12 col-md-6">
              {/* Location Filter */}
              <div className="form-group">
                <label htmlFor="location-select">Location Filter</label>
                <select
                  className="form-control"
                  id="location-select"
                  defaultValue={currentData.filter}
                  onChange={(e) => {
                    handleFilterChange({ filter: e.target.value })
                  }}
                >
                  <option value="primary">Primary Location</option>
                  <option value="all">All Locations</option>
                  <option value="sales">Sales</option>
                  <option value="service">Service</option>
                  <option value="parts">Parts</option>
                  <option value="service-and-parts">Service and Parts</option>
                  {filteredLocationData.length > 0 &&
                    filteredLocationData.map((location, index) => {
                      // Null is equivalent to Sales in our system
                      const label = `${location.name} - ${
                        location.location_type === null || location.location_type === ''
                          ? 'Sales'
                          : capitalize(location.location_type)
                      }`

                      return (
                        <option key={`location-${index}`} value={`${location.id}`}>
                          {label}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
            {locationManufacturerOptions?.length > 0 && (
              // Manufacturer selection dropdown
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="manufacturer-select">Manufacturer Filter</label>
                  <select
                    className="form-control"
                    id="manufacturer-select"
                    defaultValue={currentData.manufacturer ?? false}
                    onChange={(e) => {
                      handleFilterChange({ manufacturer: e.target.value })
                    }}
                  >
                    <option value={false}>None</option>
                    {locationManufacturerOptions?.map((manufacturer, index) => (
                      <option key={`manufacturer-${index}`} value={manufacturer}>
                        {manufacturer}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {subtypeSelectOptions?.length > 0 && (
              // Subtype selection dropdown
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="subtype-select">Subtype Filter</label>
                  <select
                    className="form-control"
                    id="subtype-select"
                    defaultValue={currentData.subtype ?? false}
                    onChange={(e) => {
                      handleFilterChange({ subtype: e.target.value })
                    }}
                  >
                    <option value={false}>None</option>
                    {subtypeSelectOptions.map((subtype, index) => (
                      <option key={`subtype-${index}`} value={subtype}>
                        {subtype}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="pt-3 border-top"></div>
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-2">
              <h6 className="mb-0">Active Locations</h6>
              <Tooltip
                tooltipTrigger={
                  <div
                    className="d-inline-flex justify-content-center align-items-center text-primary ml-1"
                    style={{ cursor: 'pointer' }}
                  >
                    <Info size="20px" stroke="currentColor" />
                  </div>
                }
                tooltipContent={
                  <div className="small">
                    <p className="mb-0">
                      Use this section to fine-tune the locations that are displayed.{' '}
                      <span className="font-weight-bold">
                        If a location is 'checked' then it will be shown on the page.
                      </span>
                    </p>
                  </div>
                }
              />
            </div>
            {/* Warn users if there are no active locations */}
            {activeLocations.filter(
              (location) => !currentData.filteredLocations.includes(location.id)
            ).length < 1 && (
              <div className="col-12">
                <Message className="w-100 mb-2" severity="warn" text="No active locations" />
              </div>
            )}
            {/* Render all active locations for fine-tuned selection */}
            {activeLocations.length > 0 &&
              activeLocations.map((location, index) => {
                const label = `${location.name} - ${
                  location.location_type === null || location.location_type === ''
                    ? 'Sales'
                    : capitalize(location.location_type)
                }`

                return (
                  <div
                    className="col-12 col-md-6 col-lg-4"
                    key={`location-${location.id}-${index}`}
                  >
                    <div className="pl-4">
                      <FormGroup>
                        <input
                          id={`location-${location.id}-${index}`}
                          type="checkbox"
                          className="form-check-input"
                          checked={!currentData?.filteredLocations?.includes(location.id)}
                          onChange={() => {
                            // Add/Remove location from filteredLocations
                            const updatedFilteredLocations =
                              !currentData?.filteredLocations?.includes(location.id)
                                ? [...currentData?.filteredLocations, location.id]
                                : currentData?.filteredLocations?.filter(
                                    (loc) => loc !== location.id
                                  )

                            // Update state and saved data
                            handleFilterChange({ filteredLocations: updatedFilteredLocations })
                          }}
                        />
                        <label
                          htmlFor={`location-${location.id}-${index}`}
                          className="form-label mb-0"
                        >
                          {label}
                        </label>
                      </FormGroup>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class LocationsTool {
  constructor({ data, config, api, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    let defaultValue = {
      filter: 'primary',
      manufacturer: false,
      subtype: false,
      filteredLocations: [],
    }

    this.data = Object.keys(data).length > 0 ? data : defaultValue

    // Add new filteredLocations key if it doesn't exist
    if (this.data.filteredLocations === undefined) {
      this.data.filteredLocations = []
    }

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  static get toolbox() {
    return {
      title: 'Location',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
      </svg>
      `,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedLocationsComponent
        onDataChange={onDataChange}
        data={this.data}
        locations={this.config?.locations}
        primaryLocationId={this.config?.primaryLocationId}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default LocationsTool
