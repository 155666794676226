import React from 'react'

import { useHits } from 'react-instantsearch'

const Hit = ({ hit }) => (
  <div className="col-md-4">
    <div className="border rounded overflow-hidden bg-white my-2">
      <img src={hit.primary_image_url} className="car-thumb-photo" />
      <div className="p-2">
        <p className="text-dark">{hit.name}</p>
        <span className="text-secondary">${(hit.price || 0).toLocaleString()}</span>
      </div>
    </div>
  </div>
)

const Hits = ({ hits }) => (
  <div className="row">
    {hits?.length > 0 ? (
      hits.map((hit) => <Hit hit={hit} key={hit.id} />)
    ) : (
      <div className="col-12">
        <span className="badge badge-warning">Warning</span>{' '}
        <span className="small">There are no cars that match the selected filters.</span>
      </div>
    )}
  </div>
)

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectHits(Component) {
  const Hits = (props) => {
    const data = useHits(props)

    return <Component {...props} {...data} />
  }

  return Hits
}

const CustomHits = connectHits(Hits)

const CarsPreview = ({ state }) => (
  <div
    className={`text-center ${state.background !== 'none' ? 'bg-' + state.background : ''} ${
      state.background === 'dark' || state.background === 'secondary' ? 'text-light' : ''
    }`}
  >
    <div className="container">
      <div className="row">
        <div className="col-12">
          {state.heading && <h3 className="text-center mb-2">{state.heading}</h3>}
          <CustomHits />
          {!state.hideButton && (
            <button className={`btn btn-${state.buttonColor ?? 'dark'} mt-2`}>
              Browse All Cars
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default CarsPreview
