import type { API, BlockAPI, BlockTune } from '@editorjs/editorjs'

import type { ScreensizeTuneProps } from '../types'

export default class ScreensizeTune implements BlockTune {
  public static isTune = true

  private api: API
  private block: BlockAPI
  private config: {
    save: () => void
  }
  private data: ScreensizeTuneProps

  constructor({ api, data, config, block }) {
    this.api = api
    this.block = block
    this.config = config
    this.data = data || { screenSize: 'all' }
  }

  render() {
    const wrapper = document.createElement('div')
    wrapper.classList.add('form-group')
    wrapper.classList.add('mb-0')

    const input = document.createElement('select')

    input.className = 'my-1 form-control form-control-sm'

    const allOption = document.createElement('option')
    allOption.value = 'all'
    allOption.text = 'All Screens'

    const mobileOption = document.createElement('option')
    mobileOption.value = 'mobile'
    mobileOption.text = 'Mobile Only'

    const mobileTabletOption = document.createElement('option')
    mobileTabletOption.value = 'mobile-tablet'
    mobileTabletOption.text = 'Mobile & Tablets'

    const desktopOption = document.createElement('option')
    desktopOption.value = 'desktop'
    desktopOption.text = 'Desktop Only'

    input.appendChild(allOption)
    input.appendChild(mobileOption)
    input.appendChild(mobileTabletOption)
    input.appendChild(desktopOption)

    input.value = this.data.screenSize

    input.addEventListener('change', (e) => {
      const target = e.target as HTMLSelectElement
      this.data.screenSize = target.value
      this.config.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    wrapper.appendChild(input)

    return wrapper
  }

  save() {
    return this.data
  }
}
