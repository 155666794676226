import type { API, BlockAPI, BlockTune } from '@editorjs/editorjs'

import { bootstrapColours, isValidHexColor, textDark, translateBootstrapColour } from '../common'
import type { BackgroundColorTuneProps } from '../types'

export default class BackgroundColorBlockTune implements BlockTune {
  public static isTune = true

  private api: API
  private block: BlockAPI
  private config: {
    save: () => void
  }
  private data: BackgroundColorTuneProps

  constructor({ api, data, config, block }) {
    this.api = api
    this.block = block
    this.config = config
    this.data = data
      ? {
          backgroundColor: data.backgroundColor ? data.backgroundColor : 'none',
          customBackgroundColor: data.customBackgroundColor
            ? data.customBackgroundColor
            : '#000000',
        }
      : { backgroundColor: 'none', customBackgroundColor: '#000000' }
  }

  render() {
    // Get the contain div (for applying styles)
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex()
    const currentBlockHolder = this.api.blocks.getBlockByIndex(currentBlockIndex).holder

    const wrapper = document.createElement('div')

    // Create the color select input element
    const colorSelect = document.createElement('select')
    colorSelect.id = 'colorSelect'
    colorSelect.className = `form-control form-control-sm bg-${this.data.backgroundColor} text-${
      textDark.includes(this.data.backgroundColor) ? 'dark' : 'white'
    }`

    // Create and append options to the select element
    function createOption(value, text, selected) {
      const option = document.createElement('option')
      option.value = value
      option.textContent = text
      if (selected) {
        option.selected = true
      }
      colorSelect.appendChild(option)
    }

    createOption('none', 'none', this.data.backgroundColor === 'none')
    bootstrapColours.forEach((color) => {
      createOption(
        color.toLowerCase(),
        translateBootstrapColour(color),
        color.toLowerCase() === this.data.backgroundColor
      )
    })
    createOption('custom', 'custom', this.data.backgroundColor === 'custom')

    // Create the color icon element
    const colorIcon = document.createElement('div')
    colorIcon.className = 'ce-popover-item__icon'
    colorIcon.innerHTML = `
      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M766.4 744.3c43.7 0 79.4-36.2 79.4-80.5 0-53.5-79.4-140.8-79.4-140.8S687 610.3 687 663.8c0 44.3 35.7 80.5 79.4 80.5zm-377.1-44.1c7.1 7.1 18.6 7.1 25.6 0l256.1-256c7.1-7.1 7.1-18.6 0-25.6l-256-256c-.6-.6-1.3-1.2-2-1.7l-78.2-78.2a9.11 9.11 0 0 0-12.8 0l-48 48a9.11 9.11 0 0 0 0 12.8l67.2 67.2-207.8 207.9c-7.1 7.1-7.1 18.6 0 25.6l255.9 256zm12.9-448.6l178.9 178.9H223.4l178.8-178.9zM904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c-4.4 0-8 3.6-8 8v-80c0-4.4-3.6-8-8-8z"></path>
      </svg>
    `

    // Create the color label element
    const colorLabel = document.createElement('div')
    colorLabel.className = 'ce-popover-item__title'
    colorLabel.textContent = 'Background Color'

    // Create the custom color input element
    const customColorInput = document.createElement('input')
    customColorInput.type = 'text'
    customColorInput.value = this.data.customBackgroundColor
    customColorInput.className = 'mr-1 form-control form-control-sm'

    const customColorBlock = document.createElement('div')
    customColorBlock.className = 'ce-popover-item__icon mr-0'
    customColorBlock.style.background = `${this.data.customBackgroundColor}`
    customColorBlock.style.width = '31px'
    customColorBlock.style.height = '31px'

    const customColorContainer = document.createElement('div')
    customColorContainer.className = `d-${
      this.data.backgroundColor === 'custom' ? 'flex' : 'none'
    } mt-1`
    customColorContainer.appendChild(customColorInput)
    customColorContainer.appendChild(customColorBlock)

    // Handle custom background color input
    customColorInput.addEventListener('input', () => {
      let customColorValue = customColorInput.value

      // Check if the value does not start with #
      if (!customColorInput.value.startsWith('#')) {
        customColorValue = '#' + customColorInput.value // Prepend # to the input value
        customColorInput.value = customColorValue
      }

      if (isValidHexColor(customColorValue)) {
        this.data.customBackgroundColor = customColorValue
        currentBlockHolder.style.background = `${this.data.customBackgroundColor}`
        customColorBlock.style.background = `${this.data.customBackgroundColor}`
        customColorInput.style.background = '#FFFFFF'
        this.config.save()
        // Force trigger Block's onChange event
        this.block.dispatchChange()
      } else {
        customColorInput.style.background = '#FFCCCB'
        customColorInput.style.color = 'var(--dark)'
        currentBlockHolder.style.background = ''
      }
    })

    // Handle color select change event
    colorSelect.addEventListener('change', () => {
      const selectedColor = colorSelect.value
      this.data.backgroundColor = selectedColor
      customColorContainer.className = `d-${
        this.data.backgroundColor === 'custom' ? 'flex' : 'none'
      }`
      colorSelect.className = `mb-1 form-control form-control-sm bg-${selectedColor} text-${
        textDark.includes(selectedColor) ? 'dark' : 'white'
      }`

      if (selectedColor === 'custom') {
        currentBlockHolder.style.backgroundColor = `${this.data.customBackgroundColor}`
        customColorBlock.style.background = `${this.data.customBackgroundColor}`
      } else {
        currentBlockHolder.style.backgroundColor = `var(--${selectedColor})`
      }

      this.config.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    // Create and append the elements to the wrapper div
    const labelRow = document.createElement('div')
    labelRow.className = 'd-flex flex-row align-items-center mb-1'
    labelRow.appendChild(colorIcon)
    labelRow.appendChild(colorLabel)

    const colorSelectRow = document.createElement('div')
    colorSelectRow.appendChild(colorSelect)

    const colorRow = document.createElement('div')
    colorRow.className = 'd-flex flex-column my-1'
    colorRow.appendChild(labelRow)
    colorRow.appendChild(colorSelectRow)
    colorRow.appendChild(customColorContainer)

    wrapper.appendChild(colorRow)

    return wrapper
  }

  save() {
    return this.data
  }
}
