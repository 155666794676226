import { useEffect, useState } from 'react'

import { BlockAPI } from '@editorjs/editorjs'
import { createRoot } from 'react-dom/client'

import { capitalise, unique } from '../../../entries/utils'
import { ToolHeader } from '../../common'
import Dialog from '../../common/Dialog'
import { renderEditSettingsButton, renderHiddenModalButton } from '../../common/commonUtils'
import {
  BrandsDataProps,
  DefaultConfigProps,
  NodesProps,
  ToolInfoProps,
  ToolboxProps,
} from '../../types'
import ModelVariantsPreview from './ModelVariantsPreview'

const RenderedModelVariantsComponent = ({
  onDataChange,
  data,
  brands,
  toolInfo,
  uniqueId,
  config,
}: {
  onDataChange: ({ manufacturer, model }: { manufacturer: string; model: string }) => void
  data: { manufacturer: string; model: string }
  brands: BrandsDataProps[]
  toolInfo: ToolInfoProps
  uniqueId: string
  config: DefaultConfigProps
}) => {
  const [show, setShow] = useState(data.model ? false : true)
  const [manufacturers, setManufacturers] = useState([])
  const [selectedManufacturer, setSelectedManufacturer] = useState(data.manufacturer ?? undefined)
  const [models, setModels] = useState([])
  const [selectedModel, setSelectedModel] = useState(data.model ?? undefined)
  const [selectedModelData, setSelectedModelData] = useState(undefined)
  const [generatedPreview, setGeneratedPreview] = useState(false)

  useEffect(() => {
    const tempManufacturers = []
    brands.map((brand) => tempManufacturers.push({ id: brand.manufacturer_id, name: brand.make }))
    if (brands.length === 0 && config?.ownerManufacturerId) {
      tempManufacturers.push({
        id: config.ownerManufacturerId,
        name: capitalise(config.ownerManufacturerId),
      })
    }
    setManufacturers(tempManufacturers)

    if (selectedModel) {
      getSelectedModelData()
    }
  }, [])

  useEffect(() => {
    if (selectedManufacturer) {
      // @ts-expect-error
      fetch(Routes.manufacturer_models_path(selectedManufacturer) + '.json')
        .then((res) => res.json())
        .then((res) => {
          setModels(res.map((model) => ({ id: model.id, name: model.name })))
        })
    }
  }, [selectedManufacturer])

  function getSelectedModelData() {
    if (selectedModel) {
      fetch(`/models/${selectedModel}.json`)
        .then((res) => res.json())
        .then((res) => {
          setSelectedModelData(res)
          setGeneratedPreview(true)
        })
    }
  }

  return (
    <>
      <ModelVariantsPreview
        selectedModelData={selectedModelData}
        selectedModel={selectedModel}
        generatedPreview={generatedPreview}
      />
      <Dialog title="Model Variants" show={show} closeClickHandler={() => setShow(false)}>
        <>
          {/* @ts-expect-error */}
          <ToolHeader {...toolInfo} />
          <div className="pt-3 border-top">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="manufacturer-select">Manufacturer Option</label>
                  <select
                    className="form-control"
                    id="manufacturer-select"
                    value={selectedManufacturer}
                    onChange={(e) => {
                      setSelectedManufacturer(e.target.value)
                      setModels([])
                      setSelectedModel(undefined)
                      setSelectedModelData(undefined)
                      setGeneratedPreview(false)
                      onDataChange({ manufacturer: e.target.value, model: undefined })
                    }}
                  >
                    <option value="">Select a manufacturer</option>
                    {manufacturers?.map((manufacturer) => (
                      <option key={manufacturer.id} value={`${manufacturer.id}`}>
                        {manufacturer.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {models.length > 0 && (
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="model-select">Model Option</label>
                    <select
                      className="form-control"
                      id="model-select"
                      value={selectedModel}
                      onChange={(e) => {
                        setSelectedModel(e.target.value)
                        setSelectedModelData(undefined)
                        setGeneratedPreview(false)
                        onDataChange({ manufacturer: selectedManufacturer, model: e.target.value })
                      }}
                    >
                      <option value="">Select a model</option>
                      {models?.map((model) => (
                        <option key={model.id} value={`${model.id}`}>
                          {model.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-12">
                <button
                  className="btn btn-primary"
                  onClick={() => getSelectedModelData()}
                  disabled={generatedPreview || !selectedModel}
                >
                  Generate Preview
                </button>
              </div>
            </div>
          </div>
        </>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ModelVariantsTool {
  private config: DefaultConfigProps
  private blockAPI: BlockAPI
  private uniqueId: string
  private data: {
    manufacturer: string
    model: string
  }
  private nodes: NodesProps
  private toolInfo: ToolInfoProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Model Variants',
      icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 11h-1.729L8.438 6H9.5l.5-.5v-4L9.5 1h-4l-.5.5v4l.5.5h1.062l-3.333 5H1.5l-.5.5v3l.5.5h3l.5-.5v-3l-.5-.5h-.068L7.5 6.4l3.068 4.6H10.5l-.5.5v3l.5.5h3l.5-.5v-3l-.5-.5zM6 5V2h3v3H6zm-2 7v2H2v-2h2zm9 2h-2v-2h2v2z"></path>
      </svg>`,
    }
  }

  constructor({ data, config, block }) {
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    let defaultValue = {
      manufacturer: undefined,
      model: undefined,
    }

    this.data = Object.keys(data).length > 0 ? data : defaultValue

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        'Select a model and show all the variants associated with that model. The available options are based on OEM websites under your dealership.',
      hideToggle: true,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData: { manufacturer: string; model: string }) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedModelVariantsComponent
        onDataChange={onDataChange}
        data={this.data}
        brands={this.config?.brands}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
        config={this.config}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default ModelVariantsTool
