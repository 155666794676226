import { ChangeEvent, MutableRefObject, PropsWithChildren, useEffect, useState } from 'react'

import { API } from '@editorjs/editorjs'
import { classNames } from 'primereact/utils'
import { Content, OnChangeStatus } from 'vanilla-jsoneditor'

import { Input, Select } from '../../../entries/FormElements'
import { LabeledFileInput, LabeledTextarea } from '../../common'

export type CustomComponentDataProps = {
  formData: {
    uniqueComponentId?: string
  }
}

const defaultComponentOptions = [] // Example: { label: 'Test', value: 'unique-component-id' }

export interface RawProps {
  type: string
  value: unknown
  key: string
}

export interface OldProps {
  text?: string
  // JSON structure is unknown since it can be [] | {}
  json?: unknown
}

export interface CCPropsData {
  uniqueComponentId: string
  customComponentId: string
  rawProps: RawProps[]
  customComponentProps: OldProps
}

export interface CustomComponentErrorOptions {
  defaultContent?: OldProps
  defaultJSON?: RawProps[]
  status?: OnChangeStatus
}

export interface PropsContext {
  addNewField: (type?: string) => void
  checkForError: (data: OldProps, options?: CustomComponentErrorOptions) => JSONStructure[]
  data: CCPropsData
  embeddedRef: MutableRefObject<HTMLDivElement | null>
  error: string
  hasDuplicateKey: (key: string, currentIndex: any) => boolean
  imageEndpoint: string
  isAdmin: boolean
  isRaw: boolean
  parsedJson: JSONStructure[]
  removeField: (i: number) => void
  setCustomComponentId: (e: any) => void
  setUniqueComponentId: (value: string) => void
  showRaw: boolean
  state: CCPropsData
  templateType: string
  unparsedJson: OldProps
  updateId: (e: ChangeEvent<HTMLSelectElement>) => void
  updateJson: (data: ChangeEvent<HTMLInputElement>, index: any) => void
  updateRawJSON: (content: Content) => void
  updateState: (updatedValue: Object) => void
  updateTab: () => void
}

export interface PropsProviderProps extends PropsWithChildren {
  data: CCPropsData
  onDataChange: (data: CCPropsData) => void
  isAdmin: boolean
  templateType: string
  editorApi: API
  imageEndpoint: string
}

export const OPTIONS = [
  { value: 'string', label: 'Text' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'object', label: 'Key/Value List' },
  { value: 'array', label: 'List' },
  { value: 'image', label: 'Image' },
] as const

export const OPTIONS_TREE = [
  { key: 'string', label: 'Text', data: 'string', icon: 'fa-solid fa-t' },
  { key: 'number', label: 'Number', data: 'number', icon: 'fa-solid fa-n' },
  { key: 'boolean', label: 'Boolean', data: 'boolean', icon: 'fa-regular fa-square-check' },
  { key: 'image', label: 'Image', data: 'image', icon: 'fa-regular fa-image' },
  { key: 'object', label: 'Key/Value List', data: 'object', icon: 'fa-solid fa-bars' },
  { key: 'array', label: 'List', data: 'array', icon: 'fa-solid fa-bars' },
]

export const BOOLEAN = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
] as const

export type TypeOptions = (typeof OPTIONS)[number]['value']

export function fetchComponentOptions(templateType: string) {
  const componentOptions = [...defaultComponentOptions]

  // ? Eventually want to add logic to fetch component options for a dealership & website
  // ? This would require the addition of a new DB table where we can store their unqiue component IDs

  if (templateType === 'careers') {
    componentOptions.push({
      label: 'Careers - Positions Available',
      value: 'careers-positions-available-default',
    })
  }

  return componentOptions
}

export interface JSONStructure {
  key: string
  value: string
  type: TypeOptions
}

export interface CustomInputsProps {
  type: TypeOptions
  wrapperClass: string
  label: string
  required: boolean
  value: unknown
  state: JSONStructure
  imageEndpoint: string
  onChange: (event) => void
  editorApi: API
}

/**
 *
 * @param value unknown type of value
 * @returns
 */
const formatNestedValue = (value: unknown) => {
  if (!!Number(value)) return Number(value)
  try {
    const json = JSON.parse(value as string)
    // If it's an array recursively go through and update all the types of the items
    if (Array.isArray(json)) {
      return json.map((l) => formatNestedValue(l))
    }
    return json
  } catch {
    return value
  }
}

// UI for the Nested input controls on the right of the user input: | ⬆️ | ⬇️ | 🗙 |
// includes index/max so we can disable the input when it's at the top/bottom for the up/down arrow
const NestedControls = ({ currentLength, handleControls, index, max }) => (
  <div className="mb-3 d-flex flex-end" style={{ gap: '0.25rem' }}>
    <button
      className="btn btn-outline-secondary"
      onClick={(e) => handleControls(e, 'up', index)}
      aria-label="Move Up"
      disabled={index === 0}
      style={{ height: '38px' }}
    >
      <i className="fa fa-arrow-up" />
    </button>
    <button
      className="btn btn-outline-secondary"
      onClick={(e) => handleControls(e, 'down', index)}
      aria-label="Move Down"
      style={{ height: '38px' }}
      disabled={index === max}
    >
      <i className="fa fa-arrow-down" />
    </button>
    <button
      className="btn btn-danger"
      disabled={currentLength <= 1}
      onClick={(e) => handleControls(e, 'delete', index)}
      aria-label="Delete"
      style={{ height: '38px' }}
    >
      <i className="fa fa-times" />
    </button>
  </div>
)

const NestedInput = ({ items, wrapperClass, label, required, onChange }) => {
  const [inputs, setInputs] = useState(items)
  const [editIndex, setEditIndex] = useState<number | null>(null)
  const [editSubIndex, setSubIndex] = useState<number | null>(null)
  const [editValue, setEditValue] = useState('')

  const handleControls = (event, command, index) => {
    setInputs((prev) => {
      // edge cases
      if (index < 0 || index >= inputs.length) return prev
      if (command === 'up' && index === 0) return prev
      if (command === 'down' && index === inputs.length - 1) return prev

      // Moving item through array
      if (command === 'up' || command === 'down') {
        const newIndex = command === 'up' ? index - 1 : index + 1
        const newArray = [...inputs]

        // Remove the item from the original index
        const [item] = newArray.splice(index, 1)

        // Insert the item at the new index
        newArray.splice(newIndex, 0, item)
        return newArray
      }

      if (command === 'delete') {
        const newItems = prev.filter((_, i) => index !== i)
        return newItems
      }

      // default
      return prev
    })
  }

  const handleChange = (event, index, subIndex) => {
    event.stopPropagation()
    const { value } = event.target
    setEditIndex(index)
    setSubIndex(subIndex)
    setEditValue(formatNestedValue(value))
  }

  const handleSave = () => {
    if (!editIndex && typeof editIndex !== 'number') return
    setInputs((prev) =>
      prev.map((item, idx) => {
        if (idx !== editIndex) return item
        if (!Array.isArray(item)) return editValue
        item[editSubIndex] = editValue
        return item
      })
    )
    setEditIndex(null)
    setEditValue('')
  }

  const hasDuplicateKey = (key, currentIndex) => {
    return inputs.some((item, i) => Array.isArray(item) && item[0] === key && i !== currentIndex)
  }

  useEffect(() => {
    onChange({ target: { name: 'value', value: inputs } })
  }, [inputs])

  return (
    <div className="w-100 mb-3">
      {inputs.map((item, i) => {
        const values = Array.isArray(item) ? item : [item]
        return (
          <div
            key={`${item}-${i}`}
            className="d-flex align-items-end justify-content-between"
            style={{ gap: '0.5rem' }}
          >
            <div className="w-100 d-flex" style={{ gap: '0.25rem' }}>
              {values.map((value, j) => {
                const updateValue = editIndex === i && editSubIndex === j ? editValue : value
                const isDuplicate = j === 0 && hasDuplicateKey(value, i)
                return (
                  <div key={`${value}-${i}-${j}`} className="position-relative w-100">
                    <Input
                      wrapperClass={wrapperClass}
                      className={classNames(
                        'form-control string required',
                        isDuplicate && 'is-invalid'
                      )}
                      label={j === 0 && values.length >= 1 ? 'Key' : label}
                      required={required}
                      value={
                        typeof updateValue === 'object' ? JSON.stringify(updateValue) : updateValue
                      }
                      onChange={(e) => handleChange(e, i, j)}
                      onBlur={() => handleSave()}
                      onKeyDown={(e) => e.key === 'Enter' && handleSave()}
                    />
                    {isDuplicate && (
                      <span
                        className="text-danger position-absolute px-0 px-lg-1"
                        style={{ bottom: 0, fontSize: 'xx-small' }}
                      >
                        Duplicate key detected. Please use unique keys.
                      </span>
                    )}
                  </div>
                )
              })}
            </div>
            <NestedControls
              handleControls={handleControls}
              currentLength={inputs.length}
              index={i}
              max={inputs.length - 1}
            />
          </div>
        )
      })}
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-success"
          onClick={() => {
            const isObject = Array.isArray(inputs[0])
            setInputs((prev) => [...prev, isObject ? ['', ''] : ''])
          }}
          aria-label="Add another string to array"
          style={{ height: '38px' }}
        >
          <i className="fa fa-plus" />
        </button>
      </div>
    </div>
  )
}

export const CustomInputs = ({
  type,
  wrapperClass,
  label,
  required,
  value,
  state,
  onChange,
  imageEndpoint,
}: CustomInputsProps) => {
  const [multiple, setMultiple] = useState(type === 'image' && Array.isArray(value))

  switch (type) {
    case 'array': {
      const items = value as unknown[]
      return (
        <div className="w-100 px-3 px-lg-0">
          <NestedInput
            items={items.flat()}
            wrapperClass={wrapperClass}
            label={label}
            required={required}
            onChange={onChange}
          />
        </div>
      )
    }
    case 'object':
      const typedValue = value as Record<string, unknown>
      const items = Object.entries(typedValue)
      const convertArrayBackToObject = (event) => {
        const updatedEvent = {
          target: { name: 'value', value: Object.fromEntries(event.target.value) },
        }
        onChange(updatedEvent)
      }
      return (
        <div className="d-flex flex-column position-relative w-100 px-3 px-lg-0">
          {/* @ts-ignore */}
          <NestedInput
            items={items}
            wrapperClass={wrapperClass}
            label={label}
            required={required}
            onChange={convertArrayBackToObject}
          />
          {/* In the case someone is messing with the JSON structure manually */}
          {typeof value !== 'object' && (
            <>
              <Input
                wrapperClass={wrapperClass}
                label={label}
                required={required}
                value={typeof value === 'object' ? JSON.stringify(value) : value}
                onChange={onChange}
              />
              <sup className="pl-1 text-danger">Invalid JSON structure.</sup>
            </>
          )}
        </div>
      )
    case 'number':
      return (
        <Input
          wrapperClass={wrapperClass}
          label={label}
          required={required}
          value={value}
          type="number"
          onChange={onChange}
        />
      )
    case 'boolean':
      return (
        <Select
          options={BOOLEAN}
          wrapperClass={wrapperClass}
          label={label}
          required={required}
          value={BOOLEAN.find((bool) => bool.value === value)}
          onChange={({ value }) => onChange({ target: { name: 'value', value } })}
          hint={undefined}
        />
      )
    case 'image':
      return (
        <div className="form-group w-100 position-relative">
          {/* @ts-ignore */}
          <Checkbox
            label="Use multiple images?"
            value={multiple}
            wrapperClass="mx-2 position-absolute"
            wrapperStyle={{ right: 0 }}
            onChange={() => {
              setMultiple((multiple) => !multiple)
              onChange({ target: { name: 'value', value: '' } })
            }}
          />
          <LabeledFileInput
            item={state}
            itemName="Image"
            // @ts-ignore Type is string | string[]
            file={{ url: value }}
            label="Image"
            accept="image/*"
            updateItem={({ Image }) => {
              const value = Array.isArray(Image) ? Image.map(({ url }) => url) : Image.url
              onChange({ target: { name: 'value', value } })
            }}
            imageEndpoint={imageEndpoint}
            multiple={multiple}
          />
        </div>
      )

    default:
      return (
        <div className="w-100 px-3 px-lg-0">
          <LabeledTextarea
            inputClassName={wrapperClass}
            label={label}
            item={state}
            itemName="value"
            customOnChange={onChange}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' || e.keyCode === 8) {
                e.stopPropagation()
              }
            }}
            style={{ minHeight: '38px', height: '38px' }}
          />
        </div>
      )
  }
}
