import { Dispatch, SetStateAction } from 'react'

import { parameterize } from '../../common'
import { LocationProps } from '../../types'

/**
 * Get the unique manufacturer options from the location data
 * @param {*} locations the Website's locations data
 * @returns an array of unique manufacturer options
 */
export function getManufacturerLocationOptions(locations: LocationProps[]) {
  const manufacturerSet = new Set()

  locations.forEach((location) => {
    if (location?.manufacturers?.length) {
      location.manufacturers.forEach((manufacturer) => {
        if (manufacturer.name) manufacturerSet.add(manufacturer.name)
      })
    }
  })

  return Array.from(manufacturerSet)
}

/**
 * Get the unique subtype options from the location data
 * @param {*} locations the Website's locations data
 * @returns an array of unique subtype options
 */
export function getSubtypeOptions(locations: LocationProps[]) {
  const subtypeSet = new Set()

  locations.forEach((location) => {
    if (location?.location_subtype?.length) {
      location.location_subtype.forEach((subtype) => {
        if (subtype) subtypeSet.add(subtype)
      })
    }
  })

  return Array.from(subtypeSet)
}

/**
 * Apply the location filter based on the selected value
 * @param {*} value the selected filter value
 * @param {*} locations the Website's locations data
 * @param {*} primaryLocationId the Website's primary location ID
 * @param {*} onDataChange the function to update the location data
 * @returns an array of filtered locations
 */
function applyLocationFilter(
  value: string,
  locations: LocationProps[],
  primaryLocationId: string,
  onDataChange: (data: any) => void
) {
  // Initialize filteredLocations with all locations
  let filteredLocations = locations

  // Switch based on the selected value
  switch (value) {
    case 'primary':
      onDataChange({ primaryLocationId: primaryLocationId })
      filteredLocations = locations.filter(
        (location) => location.id === parseInt(primaryLocationId)
      )
      break
    case 'all':
      // No filtering
      break
    case 'sales':
      filteredLocations = filteredLocations.filter(
        (location) => location.location_type === 'sales' || location.location_type === ''
      )
      break
    case 'service':
      filteredLocations = filteredLocations.filter(
        (location) => location.location_type === 'service'
      )
      break
    case 'parts':
      filteredLocations = filteredLocations.filter((location) => location.location_type === 'parts')
      break
    case 'service-and-parts':
      filteredLocations = filteredLocations.filter(
        (location) => location.location_type === 'service' || location.location_type === 'parts'
      )
      break
    default:
      // Filter by location ID
      filteredLocations = filteredLocations.filter((location) => `${location.id}` === value)
      break
  }

  return filteredLocations
}

/**
 * Apply the manufacturer filter based on the selected value
 * @param {*} locations the Website's locations data
 * @param {*} manufacturer the selected manufacturer
 * @returns an array of filtered locations
 */
function applyManufacturerFilter(locations: LocationProps[], manufacturer: string) {
  let activeLocationsData = []

  if (!manufacturer || manufacturer === 'false') {
    activeLocationsData = locations
  } else {
    // Filter location data based on the existence of the selected manufacturer
    const tempLocationData = []

    locations.forEach((location) => {
      location?.manufacturers?.filter(
        // Compare the manufacturer name to the selected manufacturer (parameterize both for consistency)
        (man) => parameterize(man.name) === parameterize(manufacturer)
      )?.length > 0
        ? tempLocationData.push(location)
        : null
    })

    activeLocationsData = tempLocationData
  }

  return activeLocationsData
}

/**
 * Apply the fine-tuned location filters
 * @param {*} locations the Website's locations data
 * @param {*} filteredLocations an array of filtered location IDs
 * @returns an array of filtered locations
 */
export function applyFineTunedLocationFilters(
  locations: LocationProps[],
  filteredLocations: number[]
) {
  let tempLocations = locations

  if (filteredLocations.length > 0) {
    // Remove filtered locations from the active locations
    tempLocations = locations.filter((location) => !filteredLocations.includes(location.id))
  }

  return tempLocations
}

/**
 * Update the active locations based on the selected filter values
 * @param {*} values an object containing the selected filter values
 * @param {*} filteredLocationData an array of filtered location data
 * @param {*} primaryLocationId the Website's primary location ID
 * @param {*} onDataChange the function to update the location data
 * @param {*} setActiveLocations the function to update the active locations
 * @param {*} setPreviewLocations the function to update the preview locations
 */
export function updateActiveLocations(
  values: {
    filter: string
    manufacturer: string
    subtype: string
    filteredLocations: number[]
  },
  filteredLocationData: LocationProps[],
  primaryLocationId: string,
  onDataChange: (data: any) => void,
  setActiveLocations: Dispatch<SetStateAction<LocationProps[]>>,
  setPreviewLocations: Dispatch<SetStateAction<LocationProps[]>>
) {
  let tempLocations = []

  // Apply general location filter
  tempLocations = applyLocationFilter(
    values.filter,
    filteredLocationData,
    primaryLocationId,
    onDataChange
  )

  // Apply manufacturer filtering if specified
  tempLocations = applyManufacturerFilter(tempLocations, values.manufacturer)

  // Apply subtype filtering if specified
  if (values.subtype && values.subtype !== 'false') {
    tempLocations = tempLocations.filter((location) =>
      location.location_subtype.includes(values.subtype)
    )
  }

  // Update active and preview locations
  setActiveLocations(tempLocations)
  setPreviewLocations(applyFineTunedLocationFilters(tempLocations, values.filteredLocations))
}
