import { MAX_COLS, MIN_COLS } from './utils'

const AddColTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Use this to add a new column. The maximum number of columns is {MAX_COLS}.
      </p>
    </div>
  ),
}

const DeleteColTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Use this to delete a column. The minimum number of columns is {MIN_COLS}.
      </p>
    </div>
  ),
}

export { AddColTooltip, DeleteColTooltip }
