import { default as React, useCallback, useState } from 'react'

import { createRoot } from 'react-dom/client'

import { capitalise, parameterize, unique } from '../../../entries/utils'
import {
  EmptyPlaceHolder,
  ItemOption,
  LabeledCheckbox,
  LabeledFileInput,
  LabeledInput,
  LabeledSelect,
  LabeledTextarea,
  ToolHeader,
  bootstrapColours,
  buttonOptions,
  useActions,
} from '../../common'
import Dialog from '../../common/Dialog'
import { renderEditSettingsButton, renderHiddenModalButton } from '../../common/commonUtils'
import BrandDescriptionPreview, { ButtonPreview } from './BrandDescriptionPreview'

/**
 * starters - An object containing starter options for the BrandDescription component.
 * Dealership brands are added to this on initialization of the BrandDescription component.
 */
const starters = {
  empty: {
    isManufacturer: false,
    useCustomLogo: true,
    logo: null,
    heading: '',
    useCustomDescription: true,
    description: '',
    btns: [],
  },
}

/**
 * initialState - Generates an initial state for the BrandDescription component.
 *
 * @param {Object} data - Initial data for the BrandDescription component.
 * @returns {Object} - The initial state object.
 */
const initialState = (data) => {
  return {
    isManufacturer: data.isManufacturer ?? false,
    useCustomLogo: data.useCustomLogo ?? true,
    logo: data.logo ?? null,
    heading: data.heading,
    useCustomDescription: data.useCustomLogo ?? true,
    description: data.description,
    btns: data.btns || [],
    activated: !!data.activated,
    starter: 'empty',
  }
}

/**
 * defaultItem - Generates a default button item.
 *
 * @returns {Object} - The default button item.
 */
const defaultItem = () => {
  return {
    id: unique(),
    linkText: `Link Text`,
    href: `/href`,
    btnColour: 'primary',
    btnType: 'fill',
  }
}

/**
 * ButtonEditor - A React component for editing and previewing a button item.
 *
 * @param {Object} props - Component props.
 * @param {number} props.index - Index of the button item.
 * @param {Object} props.item - The button item to be edited/previewed.
 * @param {Function} props.updateItem - Callback for updating the item.
 * @param {Function} props.deleteItem - Callback for deleting the item.
 * @param {Function} props.moveUp - Callback for moving the item up in the list.
 * @returns {JSX.Element} - The rendered component.
 */
export const ButtonEditor = ({ index, item, updateItem, deleteItem, moveUp }) => {
  // State management using React hooks
  const [edit, setEdit] = useState(true)

  // Determine if the item is editable
  const editable = updateItem && edit

  return (
    <ItemOption
      edit={edit}
      index={index}
      item={item}
      title="Button"
      setEdit={setEdit}
      hideBtnText
      moveUp={moveUp}
      deleteItem={deleteItem}
    >
      {editable ? (
        // Render form field inputs for editing
        <div className="row">
          <div className="col-12 mt-2">
            <LabeledInput item={item} itemName="href" label="Link URL" updateItem={updateItem} />
          </div>
          <div className="col-12 mt-2">
            <LabeledInput
              item={item}
              itemName="linkText"
              label="Link Text"
              updateItem={updateItem}
            />
          </div>
          <div className="col-12 mt-2">
            <LabeledSelect
              item={item}
              itemName="btnType"
              label="Button Style"
              updateItem={updateItem}
              options={Array.from(buttonOptions)}
            />
          </div>
          <div className="col-12 mt-2">
            <LabeledSelect
              item={item}
              label="Button Colour"
              itemName="btnColour"
              updateItem={updateItem}
              options={Array.from(bootstrapColours)}
            />
          </div>
        </div>
      ) : (
        // Render button preview if not in edit mode
        <ButtonPreview type={item.btnType} colour={item.btnColour} isLastButton={true}>
          {item.linkText}
        </ButtonPreview>
      )}
    </ItemOption>
  )
}

/**
 * BrandDescription - A React component for managing and displaying brand information.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.data - The data representing brand information.
 * @param {Object} props.toolInfo - Information about the tool.
 * @param {Function} props.onDataChange - Callback for updating data.
 * @param {string} props.imageEndpoint - URL endpoint for images.
 * @param {number} props.uniqueId - Unique ID for the component.
 * @returns {JSX.Element} - The rendered component.
 */
const RenderedBrandDescriptionComponent = ({
  data,
  toolInfo,
  onDataChange,
  imageEndpoint,
  uniqueId,
}) => {
  // State management using React hooks
  const [state, setState] = useState(initialState(data))
  const { heading, activated, description } = state
  const [show, setShow] = useState(activated ? false : true)
  const [items, setItems] = useState(data.btns || [])
  const [starter, setStarter] = useState('empty')

  /**
   * Callback function to update items and trigger onDataChange
   * Updates the items that are added/removed (i.e. buttons)
   */
  const syncedItemsUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      onDataChange({
        btns: [...updatedItems],
      })
    },
    [onDataChange]
  )

  /**
   * Callback function to update state and trigger onDataChange
   * Updates the common fields (i.e. heading, description, logo)
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
        starter: state.starter,
      })
      onDataChange(item)
    },
    [state.starter, onDataChange]
  )

  // Other logic for managing actions and UI rendering
  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate: syncedItemsUpdate,
  })

  const addStarterContent = () => {
    const initialBlocks = starters[starter]
    onDataChange({ ...initialBlocks, activated: true })
    setItems(initialBlocks.btns)
    syncedStateUpdate({ ...initialBlocks, activated: true })
  }

  return (
    <>
      {activated ? (
        <>
          <BrandDescriptionPreview
            state={state}
            heading={heading}
            description={description}
            items={items}
          />
        </>
      ) : (
        <span className="text-danger">Please select a starter option...</span>
      )}
      <Dialog title="Brand Description" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} />
        <div className="pt-3 border-top">
          <div className="row">
            {activated ? (
              <>
                <div className="col-12 col-md-6">
                  {state.isManufacturer && (
                    <>
                      <p className="text-danger">
                        If you want your logo to stay up-to-date with our manufacturer updates,
                        please leave this unchecked.
                      </p>
                      <div className="pl-4">
                        <LabeledCheckbox
                          controlled={false}
                          item={state}
                          itemName="useCustomLogo"
                          label="Use Custom Logo"
                          updateItem={syncedStateUpdate}
                        />
                      </div>
                    </>
                  )}
                  {state.useCustomLogo && (
                    <LabeledFileInput
                      item={state}
                      itemName="logo"
                      file={state.logo}
                      updateItem={syncedStateUpdate}
                      imageEndpoint={imageEndpoint}
                    />
                  )}
                </div>
                <div className="col-12 col-md-6 mt-2 mt-md-0">
                  <LabeledInput
                    controlled={false}
                    item={state}
                    itemName="heading"
                    label="Heading"
                    updateItem={syncedStateUpdate}
                  />
                </div>
                <div className="col-12 mt-2">
                  {state.isManufacturer && (
                    <>
                      <p className="text-danger">
                        If you want your description to stay up-to-date with our manufacturer
                        updates, please leave this unchecked.
                      </p>
                      <div className="pl-4">
                        <LabeledCheckbox
                          controlled={false}
                          item={state}
                          itemName="useCustomDescription"
                          label="Use Custom Description"
                          updateItem={syncedStateUpdate}
                        />
                      </div>
                    </>
                  )}
                  {state.useCustomDescription && (
                    <LabeledTextarea
                      controlled={false}
                      item={state}
                      itemName="description"
                      label="Description"
                      updateItem={syncedStateUpdate}
                    />
                  )}
                </div>

                <div className="col-12 mb-2 d-flex align-items-end justify-content-between">
                  <span>Buttons</span>
                  <button className="btn btn-sm btn-primary" onClick={actions.addItem}>
                    Add New Button
                  </button>
                </div>

                {items.length > 0 ? (
                  items.map((item, index) => (
                    <div
                      className={
                        'col-lg-6 ' +
                        (index !== items.length - 1 && index !== items.length - 2 ? 'mb-3' : '')
                      }
                      key={index}
                    >
                      <ButtonEditor index={index} item={item} {...actions} />
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <EmptyPlaceHolder itemName="button" />
                  </div>
                )}
              </>
            ) : (
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="starter" className="form-label">
                    Select a starter option
                  </label>
                  <select
                    id={'starter'}
                    className="form-control"
                    value={starter}
                    onChange={(e) => setStarter(e.target.value)}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {Object.keys(starters).map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <button className="btn btn-primary" onClick={addStarterContent}>
                  Get Started
                </button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

/**
 * BrandDescriptionTool - A class representing a tool for managing and displaying brand information.
 *
 * @class BrandDescriptionTool
 */
class BrandDescriptionTool {
  /**
   * Creates an instance of BrandDescriptionTool.
   *
   * @param {Object} options - The tool configuration options.
   * @param {Object} options.data - Initial data for the tool.
   * @param {Object} options.api - An API object for interactions.
   * @param {Object} options.config - Configuration settings for the tool.
   */
  constructor({ data, api, config, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    // Add dealership makes to starter options
    config.brands &&
      config.brands.map(
        (option) =>
          option.make &&
          (starters[option.make] = {
            isManufacturer: option.make,
            useCustomLogo: false,
            logo: option.logo,
            heading: `About ${option.make}`,
            useCustomDescription: false,
            description: option.description || '',
            btns: [
              {
                id: unique(),
                linkText: `View ${capitalise(option.make)} Stock`,
                href: `/search/${parameterize(option.make)}`,
                btnColour: 'primary',
                btnType: 'fill',
              },
              {
                id: unique(),
                linkText: `View ${capitalise(option.name)} Website`,
                href: option.url,
                btnColour: 'primary',
                btnType: 'outline',
              },
            ],
          })
      )

    const defaultData = {
      activated: false,
      isManufacturer: false,
      useCustomLogo: true,
      logo: null,
      heading: '',
      useCustomDescription: true,
      description: '',
      btns: [],
    }

    // Initialize data with provided data or defaultData
    this.data = Object.keys(data).length ? data : defaultData

    // Update logo/description if using manufacturer data
    if (data.isManufacturer) {
      if (!data.useCustomLogo) {
        this.data.logo = config.brands.filter((brand) => brand.make === data.isManufacturer)[0].logo
      }
      if (!data.useCustomDescription) {
        this.data.description = config.brands.filter(
          (brand) => brand.make === data.isManufacturer
        )[0].description
      }
    }

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText: `Display information about a brand using an image, text and links.`,
      itemName: 'Brand Description',
      hideToggle: true,
    }
  }

  /**
   * A getter method for defining the toolbox configuration.
   * Defined title/icon shown in the "Add" toolbar.
   *
   * @static
   * @returns {Object} - Toolbox configuration with title and icon.
   */
  static get toolbox() {
    return {
      title: 'Brand Description',
      icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"></path></svg>`,
    }
  }

  /**
   * Renders the brand description tool's user interface.
   *
   * @returns {HTMLElement} - The root element containing the tool's UI.
   */
  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    // Callback function for updating data and saving configuration
    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedBrandDescriptionComponent
        onDataChange={onDataChange}
        data={this.data}
        toolInfo={this.toolInfo}
        imageEndpoint={this.config.imageUrl}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  /**
   * Saves the current tool data.
   *
   * @async
   * @returns {Object} - The current data object.
   */
  async save() {
    return this.data
  }
}

export default BrandDescriptionTool
