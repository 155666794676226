import { default as React } from 'react'

import { createRoot } from 'react-dom/client'

import { unique } from '../../../entries/utils'
import { renderEditSettingsButton } from '../../common/commonUtils'
import SpecialOffersViewer from './SpecialOffersViewer'

class SpecialOffersTool {
  static get toolbox() {
    return {
      title: 'Special Offers',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
      </svg>`,
    }
  }

  constructor({ data, config, api, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    this.data = {
      searchState: data.searchState || {},
      dateFilter: data.dateFilter || null,
    }

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <SpecialOffersViewer onDataChange={onDataChange} data={this.data} uniqueId={this.uniqueId} />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default SpecialOffersTool
